import { middlewareRequest } from "../middle-tier/middlewareHelpers";
import { getDocumentFilename, getFileProperties, getTextFromDocument, setDocumentTitle } from "./wordHelpers";

/* global */

/*
v2 query
*/

export interface VectaraQueryApiRequest {
  query: string;
  numResults: number;
  search: VectaraQueryApiSearch;
  generation?: VectaraQueryApiGeneration;
  stream_response?: boolean;
}

/*interface VectaraQueryApiRequestV2 {
// Unused for now
/* interface VectaraQueryApiRequestV2 {
  query: string;
  search: VectaraQueryApiSearch;
  generation?: VectaraQueryApiGenerationV2;
  stream_response?: boolean;
}*/

interface VectaraQueryApiSearch {
  corpora: VectaraQueryApiSearchCorpora[];
  offset?: number;
  limit?: number;
  context_configuration?: VectaraQueryApiSearchContextConfig;
  reranker?: VectaraQueryApiSearchReranker;
}

interface VectaraQueryApiSearchCorpora {
  custom_dimensions?: Map<string, string>;
  metadata_filter?: string;
  lexical_interpolation?: number;
  semantics?: string;
  corpus_key: string;
}

interface VectaraQueryApiSearchContextConfig {
  characters_before?: number;
  characters_after?: number;
  sentences_before?: number;
  sentences_after?: number;
  start_tag?: string;
  end_tag?: string;
}

interface VectaraQueryApiSearchReranker {
  type?: string;
  reranker_id?: string;
  diversity_bias?: number;
}

interface VectaraQueryApiGeneration {
  prompt_name?: string;
  max_used_search_results?: number;
  prompt_text?: string;
  max_response_characters?: number;
  response_language?: string;
  model_parameters?: VectaraQueryApiGenerationModelParams;
  citations?: VectaraQueryApiGenerationCitations;
  enable_factual_consistency_score?: boolean;
}

/*interface VectaraQueryApiGenerationV2 {
  generation_preset_name?: string;
  max_used_search_results?: number;
  prompt_text?: string;
  max_response_characters?: number;
  response_language?: string;
  model_parameters?: VectaraQueryApiGenerationModelParams;
  citations?: VectaraQueryApiGenerationCitations;
  enable_factual_consistency_score?: boolean;
}*/

interface VectaraQueryApiGenerationModelParams {
  max_tokens: number;
  temperature: number;
  frequency_penalty: number;
  presence_penalty: number;
}

interface VectaraQueryApiGenerationCitations {
  style: string;
  url_pattern: string;
  text_pattern: string;
}

interface VectaraQueryApiResponse {
  summary: string;
  response_language: string;
  search_results: VectaraQueryApiSearchResults[];
  factual_consistency_score: number;
}

interface VectaraQueryApiSearchResults {
  text: string;
  score: number;
  part_metadata: Map<string, string>;
  document_metadata: Map<string, string>;
  document_id: string;
  request_corpora_index: number;
}

interface DocumentProperties {
  title: string;
}

export interface MultiQuery {
  query: string;
  prompt: string;
}

export async function uploadDocument(
  documentId: string,
  documentText: string,
  documentProperties: DocumentProperties,
  corpusKey?: string,
  apiKey?: string,
  corpusType?: string,
): Promise<void> {
  console.log("Uploading Document...");

  const path = "/document/add";

  if (!documentId || documentId === "") {
    documentId = documentProperties.title;
  }

  const headers = {
    "document-body-type": "core", //"structured", // "core"
    "corpus-type": corpusType || "analyze", //"structured", // "core"
    "corpus-key": corpusKey || "",
    "x-api-key": apiKey || "",
  };

  // cut the document into document parts where each text can only have 15K characters
  const documentParts = [];
  const sections = [];
  const maxLength = 1000;
  let id = 1;
  for (let i = 0; i < documentText.length; i += maxLength) {
    documentParts.push({ text: documentText.substring(i, i + maxLength) });
    sections.push({ id: id++, text: documentText.substring(i, i + maxLength) });
  }

  const payload = {
    id: documentId,
    corpusType: corpusType,
    type: "core", // "structured"
    // metadata
    sections: sections,
    document_parts: documentParts,
  };

  await middlewareRequest("POST", path, payload, headers);

  console.log("Document Added successfully");

  /*

    const payload = {
      customerId: customerId,
      corpusId: corpusId,
      document: {
        documentId: documentId,
        title: documentProperties.title,
        section: [
          {
            id: 1,
            text: documentText,
            title: "whole",
            metadatajson: "{}",
          },
        ],
        metadatajson: JSON.stringify(documentProperties),
      },
    };

*/
}

export async function deleteDocument(
  documentId: string,
  documentProperties: DocumentProperties,
  corpusKey?: string,
  apiKey?: string,
  corpusType?: string,
): Promise<void> {
  console.log("Deleting document...");
  const apiPath = "/document/delete";

  if (!documentId || documentId === "") {
    documentId = documentProperties.title;
  }

  const headers = {
    "document-id": documentId,
    "corpus-type": corpusType || "analyze", //"structured", // "core"
    "corpus-key": corpusKey || "",
    "x-api-key": apiKey || "",
  };

  await middlewareRequest("DELETE", apiPath, null, headers);

  console.log("Document deleted successfully");
}

export async function setupDocumentSync() {
  try {
    const documentProperties = await getFileProperties();

    const docFile = await getDocumentFilename();

    if (documentProperties.title == "" || !documentProperties.title) {
      await setDocumentTitle(docFile);
    } else {
      console.log(`Document title is already set to: ${documentProperties.title}`);
    }
  } catch (error) {
    console.error(`Error: ${error}`);
  }
}

export async function vectaraFileUpload(
  documentId: string,
  documentText: string,
  documentProperties: DocumentProperties,
  apiKey: string,
  corpusId: number,
  customerId: number,
): Promise<Response> {
  try {
    const apiUrl = `${process.env.BACKEND_API_URL}/file/index`;

    const payload = {
      customerId: customerId,
      corpusId: corpusId,
      document: {
        documentId: documentId,
        title: documentProperties.title,
        section: [
          {
            id: 1,
            text: documentText,
            title: "whole",
            metadatajson: "{}",
          },
        ],
        metadatajson: JSON.stringify(documentProperties),
      },
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "customer-id": customerId.toString(),
        "x-api-key": apiKey,
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(apiUrl, options);
    if (!response.ok) {
      throw new Error("Error while uploading document " + response.status + response.statusText);
    }
    console.log(await response.text());
    console.log(response.status);

    return response;
  } catch (error) {
    console.error("Error: " + error);
    throw error; // Rethrow the error to be handled by the caller
  }
}

export async function conductAnalyzeV2(
  documentId: string,
  upload = false,
  vectaraQueryApiRequest: VectaraQueryApiRequest,
) {
  const documentText = await getTextFromDocument();
  const documentProperties = await getFileProperties();

  if (documentText.trim().length == 0) {
    return;
  }

  try {
    await uploadDocument(documentId, documentText, documentProperties);
  } catch (error) {
    console.error(`Error uploading document: ${error} - ${upload}`);
  }

  const queryResponse = await queryV2(vectaraQueryApiRequest, documentId);

  const references = createRagReferenceDropdown(queryResponse);

  const summaryResult = queryResponse.summary;

  // TODO: Can you clean up the document later? Deleting the document after every call seems a bit excessive?
  try {
    await deleteDocument(documentId, documentProperties);
  } catch (error) {
    console.error("Error deleting document: " + error);
  }

  return { summaryResult, references };
}

export async function conductAnalyze(
  _documentId: string,
  queryText: string,
  upload = false,
  promptText?: string,
  modelName?: string,
) {
  const documentText = await getTextFromDocument();
  const documentProperties = await getFileProperties();

  if (documentText.trim().length == 0) {
    return;
  }

  // todo: upload
  // if (upload) {
  // await deleteDocument(documentProperties.title, documentProperties);
  try {
    await uploadDocument(documentProperties.title, documentText, documentProperties);
  } catch (error) {
    console.error(`Error uploading document: ${error} - ${upload}`);
  }
  // }

  const queryResponse = await query(
    queryText,
    documentProperties.title,
    `doc.id='${documentProperties.title}'`,
    promptText,
    modelName,
  );

  const references = createRagReferenceDropdown(queryResponse);

  const summaryResult = queryResponse.summary;

  // TODO: Can you clean up the document later? Deleting the document after every call seems a bit excessive?
  try {
    await deleteDocument(documentProperties.title, documentProperties);
  } catch (error) {
    console.error("Error deleting document: " + error);
  }

  return { summaryResult, references };
}

export const conductMultiQuery = async (_documentId: string, _documentText: string, queries: MultiQuery[]) => {
  const documentProperties = await getFileProperties(); // TODO

  if (_documentText.length == 0) {
    return;
  }

  try {
    await uploadDocument(_documentId, _documentText, documentProperties);
  } catch (error) {
    console.error(`Error uploading document: ${error}`);
  }

  const results = await Promise.all(
    queries.map(async (queryText) => {
      const response = await query(queryText.query, _documentId, `doc.id='${_documentId}'`, queryText.prompt, "", 10);
      return response.summary;
    }),
  );

  try {
    await deleteDocument(_documentId, documentProperties);
  } catch (error) {
    console.error("Error deleting document: " + error);
  }

  // TODO: Can you clean up the document later? Deleting the document after every call seems a bit excessive?

  return results;
};

export async function queryV2(vectaraQueryApiRequest: VectaraQueryApiRequest, documentId?: string) {
  const path = "/query";
  console.log("Executing a query...");

  // todo
  var metadataFilterVal = vectaraQueryApiRequest.search.corpora[0].metadata_filter;
  const docTitle = (await getFileProperties()).title;
  if (!metadataFilterVal) {
    if (documentId) {
      metadataFilterVal = "doc.id = " + `'${documentId}'`;
    } else {
      metadataFilterVal = "doc.id = " + `'${docTitle}'`;
    }
  }

  console.log("prompt_text: ", vectaraQueryApiRequest.generation.prompt_text);

  const response = await middlewareRequest<VectaraQueryApiResponse>("POST", path, vectaraQueryApiRequest);
  // const response = await middlewareRequest<VectaraQueryApiResponse>("POST", path, requestV2);
  return response;
}

export async function query(
  queryText: string,
  documentId?: string, // todo remove
  metadataFilterVal?: string, // todo remove
  promptText?: string,
  modelName?: string,
  maxSearchResult: number = 15,
  lexical_interpolation?: number,
  semantics?: string,
): Promise<VectaraQueryApiResponse> {
  const path = "/query";
  console.log("Executing a query...");

  // todo
  const docTitle = (await getFileProperties()).title;
  if (!metadataFilterVal) {
    if (documentId) {
      metadataFilterVal = "doc.id = " + `'${documentId}'`;
    } else {
      metadataFilterVal = "doc.id = " + `'${docTitle}'`;
    }
  }

  const request: VectaraQueryApiRequest = {
    query: queryText,
    numResults: 20,
    search: {
      corpora: [
        {
          // Corpus key is injected server side.
          corpus_key: "",
          metadata_filter: metadataFilterVal,
          lexical_interpolation: lexical_interpolation ?? 0,
          semantics: semantics ?? "default",
        },
      ],
      context_configuration: {
        sentences_after: 2,
        sentences_before: 2,
        start_tag: "<b>",
        end_tag: "</b>",
      },
    },
    generation: {
      // Seems like we rarely use more than 10 search results even if we have chunks of 1000 chars.
      max_used_search_results: maxSearchResult,
      // prompt_name: "mockingbird-1.0-2024-07-16",
      // Omni is better for summarizing without getting too many too much text to summarize issues with other prompt name models.
      // prompt_name: "vectara-summary-ext-24-05-med-omni",
      prompt_name: modelName ?? "vectara-summary-ext-24-05-large",
      enable_factual_consistency_score: false,
      prompt_text: promptText,
      response_language: "eng",
      model_parameters: {
        max_tokens: 2048,
        temperature: 0.1,
        frequency_penalty: 0,
        presence_penalty: 0,
      },
    },
  };
  // Commenting this out because v2 has some things we don't necessarily want changed right now. V2 has different naming of generation parameters.
  // const requestV2: VectaraQueryApiRequestV2 = {
  //   query: queryText,
  //   search: {
  //     corpora: [
  //       {
  //         corpus_key: "",
  //         metadata_filter: metadataFilterVal,
  //         lexical_interpolation: 0.01,
  //       },
  //     ],
  //     context_configuration: {
  //       sentences_after: 3,
  //       sentences_before: 3,
  //       start_tag: "<b>",
  //       end_tag: "</b>",
  //     },
  //   },
  //   generation: {
  //     // Seems like we rarely use more than 10 search results even if we have chunks of 1000 chars.
  //     max_used_search_results: 15,
  //     // generation_preset_name: "mockingbird-1.0-2024-07-16",
  //     // generation_preset_name: "vectara-summary-ext-24-05-large",
  //     // Omni is better for summarizing without getting too many too much text to summarize issues with other prompt name models.
  //     generation_preset_name: "vectara-summary-ext-24-05-med-omni",
  //     // generation_preset_name: "vectara-summary-ext-24-05-sml",
  //     // generation_preset_name: "vectara-summary-ext-v1.2.0",
  //     max_response_characters: 1024,
  //     enable_factual_consistency_score: false,
  //     prompt_text: promptText ?? "",
  //   },
  // };
  console.log("prompt_text: ", promptText);

  const response = await middlewareRequest<VectaraQueryApiResponse>("POST", path, request);
  // const response = await middlewareRequest<VectaraQueryApiResponse>("POST", path, requestV2);
  return response;
}

export function createRagReferenceDropdown(queryResponse: VectaraQueryApiResponse) {
  const analysisResult = queryResponse.summary;

  if (!analysisResult) {
    return [];
  }

  const referencesFoundInResult = new Set(
    analysisResult.match(/\[\d+\]/g)?.map((match: string) => match.replace(/\D/g, "")),
  );

  const referencesList = Array.from(referencesFoundInResult);
  referencesList.sort((a, b) => parseInt(a) - parseInt(b));

  const references = queryResponse.search_results;

  console.log("References found in result: ", { referencesFoundInResult, referencesList, references });

  const list = [];

  console.log("References: ", references);

  for (const reference of referencesList) {
    console.log("Create option for reference: " + reference);
    const referenceText = references[parseInt(reference) - 1] ? references[parseInt(reference) - 1].text : "";
    list.push({ text: referenceText, value: reference });
  }

  return list;
}
