import { Config } from "../context/ConfigurationContext";

export const configuration: Config = {
  corpusId: "",
  customerId: "",
  appTitle: "LexIQ",
  apiKey: "",
  endpoint: "api.vectara.io",
  questions: ["What are the most important parts about this document?"],
  sessionId: "1234",
};
