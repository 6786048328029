/* global  */
export const openAiQuery = async (queryText: string, promptText?: string, referenceText?: string): Promise<string> => {
  //  Ideally these would be pushed to the backend and calls made through there. Eventually this will be deprecated.
  // API key and URL for gpt-3.5-turbo model
  // Prepare your question in a chat message format
  const messages = [
    { role: "user", content: queryText },
    { role: "system", content: "Please respond only in plain text. Avoid any markdown, HTML, or other formatting." }, // todo: the old version only has plain. So until we can format it, use plain.
  ];

  if (promptText) {
    messages.push({ role: "assistant", content: promptText });
  }

  if (referenceText) {
    messages.push({ role: "assistant", content: referenceText });
  }

  // todo: this will be moved to the backend eventually.
  const apiKey = "965071bfe85140db9651313c73b3f98b";
  const apiUrl =
    "https://ai-lexiqsamarthgupta990871837324.openai.azure.com/openai/deployments/gpt-4o/chat/completions?api-version=2023-03-15-preview";

  // Make the OpenAI API call
  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "api-key": apiKey,
      },
      body: JSON.stringify({
        messages: messages,
        temperature: 0.7, // Adjust as needed
      }),
    });

    if (!response.ok) {
      throw new Error(`OpenAI API request failed with status ${response.status}`);
    }

    const data = await response.json();
    const summary = data.choices[0].message.content.trim();

    return summary as string;
  } catch (error) {
    console.error("Error calling OpenAI:" + error);
    return error;
  }
};
