// Hard coded values for now. These will have to change when we have to switch to the production environment.
export const FEATURE_IDS = {
  CHAT: process.env.STRIPE_FEATURE_CHAT || "",
  // These are all core contract analysis features.
  RISK_ANALYSIS: process.env.STRIPE_FEATURE_RISK_ANALYSIS || "",
  SUMMARIZE_CONTRACT: process.env.STRIPE_FEATURE_SUMMARIZE_CONTRACT || "",
  SUMMARIZE_DATES: process.env.STRIPE_FEATURE_SUMMARIZE_DATES || "",
  SUMMARIZE_SELECTION: process.env.STRIPE_FEATURE_SUMMARIZE_SELECTION || "",
  DRAFT: process.env.STRIPE_FEATURE_DRAFT || "",
};

export const ALL_FEATURE_IDS = Object.values(FEATURE_IDS);
