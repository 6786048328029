import { useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import { middlewareRequest } from "../middle-tier/middlewareHelpers";
import { useCheckout } from "../context/CheckoutContext";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles, tokens, Button } from "@fluentui/react-components";
import { ChevronLeftRegular } from "@fluentui/react-icons";
import { useState } from "react";
import ErrorModal from "./ErrorModal";

interface SessionStatusResponse {
  clientSecret: string;
  sessionId: string;
}
// TODO: Change this to the real key and store in .env
// There are rate limits to this to about 100 rps prod/ 25 rps dev.
const stripePromise = loadStripe(process.env.STRIPE_KEY || "");

const useStyles = makeStyles({
  checkoutForm: {
    display: "flex",
    flexDirection: "column",
    gap: tokens.spacingVerticalM,
  },
  breadcrumb: {
    display: "flex",
    alignItems: "center",
    fontSize: tokens.fontSizeBase300,
    color: tokens.colorNeutralForeground1,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

const CheckoutForm = () => {
  const { priceId, setCheckoutSessionId } = useCheckout(); // Get the lookupKey from context
  const navigate = useNavigate();
  const location = useLocation();

  const fetchClientSecret = useCallback(async () => {
    console.log("On fetchClientSecret for checkoutform");
    return await middlewareRequest("POST", "/create-checkout-session", { price_id: priceId })
      .then((res) => res)
      .then((data: SessionStatusResponse) => {
        // I couldn't figure out a way to get the sessionId from the resopnse from the backend so I am doing this hacky way of getting it from the response and setting it in the context. We aren't supposed to give back the sessionId to the frontend since the fetchClientSecret is supposed to be just the secret, but I couldn't figure out how to do it without this. It's possible that we could just make another call back to the backend to get the sessionId before this function runs.
        setCheckoutSessionId(data.sessionId);
        console.log("Checkout session ID set to:", data.sessionId);
        return data.clientSecret;
      })
      .catch((error) => {
        console.error("Error fetching client secret:", error);
        setError("An error occurred while processing your request. Please try again.");
        throw error;
      });
  }, [priceId, setCheckoutSessionId]);

  const onComplete = () => {
    console.log("On complete for checkoutform");
    navigate("/return", { state: location.state });
  };

  const options = { fetchClientSecret };

  const styles = useStyles();

  const [error, setError] = useState<string | null>(null);

  const handleBack = () => {
    const { history } = (location.state as { history?: string[] }) || {};
    if (history && history.length > 0) {
      const previousPage = history.pop();
      navigate(previousPage, { state: { history } });
    } else {
      navigate("/stripe");
    }
  };

  return (
    <div className={styles.checkoutForm}>
      <Button appearance="subtle" icon={<ChevronLeftRegular />} onClick={handleBack} className={styles.breadcrumb}>
        Back to Pricing
      </Button>
      {error ? (
        <ErrorModal
          message={error}
          onClose={() => navigate("/stripe", { state: location.state })}
          navigateTo="/stripe"
          navigateButtonText="Back to Pricing"
        />
      ) : (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{
            ...options,
            onComplete: onComplete,
          }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export default CheckoutForm;
