import * as React from "react";

import { makeStyles } from "@fluentui/react-components";
import Logo from "../../../assets/lexiq.png";
import { AI_AGENTS } from "../components/AiAgents";
import { PROMPT_DETAILED } from "../components/DocumentTools";
import Header from "../components/Header";
import HeroList, { HeroListItem } from "../components/HeroList";

interface AppProps {
  title: string;
}

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    paddingBottom: "20px",
  },
});

const listItems: HeroListItem[] = [
  {
    primaryText: "Chat with LexIQ",
    to: "/chat",
  },
  ...PROMPT_DETAILED.map((item) => ({
    primaryText: item.title,
    to: item.to,
  })),
  ...AI_AGENTS.map((item) => ({
    primaryText: item.title,
    to: item.to,
  })),
];

const Root: React.FC<AppProps> = (props: AppProps) => {
  const styles = useStyles();
  // The list items are static and won't change at runtime,
  // so this should be an ordinary const, not a part of state.

  return (
    <div className={styles.root}>
      <Header logo={Logo} title={props.title} message="Welcome to LexIQ" />
      <HeroList message="" items={listItems} />
    </div>
  );
};

export default Root;
