import Markdown from "markdown-to-jsx";

import React, { useEffect, useMemo } from "react";

import { Button, Text, makeStyles, tokens } from "@fluentui/react-components";
import { ApiV2 } from "@vectara/stream-query-client";
import { SUMMARY_NUM_RESULTS, useSearchContext } from "../context/SearchContext";
import { applyCitationOrder, extractCitations, reorderCitations } from "../helpers/citations";
import { ChatReferences } from "./ChatReferences";
import { useTypewriter } from "../hooks/useTypewriter";

const removeCitations = (text: string) => text.replace(/ ?\[\d+\]/g, "");

const markDownCitations = (summary: string) => {
  const citations = extractCitations(summary);
  return citations
    .reduce((accum, { text, references }) => {
      if (references) {
        accum.push(text);

        const marginBefore = text ? text[text.length - 1] !== " " : false;
        if (marginBefore) {
          accum.push(" ");
        }

        references.forEach((reference, referenceIndex) => {
          if (referenceIndex > 0) {
            accum.push(" ");
          }

          accum.push(`<SummaryCitation reference={${reference}} />`);
        });
      } else {
        accum.push(text);
      }

      return accum;
    }, [] as string[])
    .join(" ");
};

const SummaryCitation = ({ reference }: { reference: string }) => {
  return (
    <>
      {" "}
      <span className="chatSummaryCitation">{reference}</span>
    </>
  );
};

type Props = {
  isLoading?: boolean;
  isSummarizing?: boolean;
  question?: string;
  answer?: string;
  searchResults?: ApiV2.Query.SearchResult[];
  error?: React.ReactNode;
  isReferencesOpen?: boolean;
  setIsReferencesOpen?: (isOpen: boolean) => void;
  typewrite?: boolean;
};

const useStyles = makeStyles({
  chatItem: {
    display: "flex",
    flexDirection: "column",
    rowGap: tokens.spacingVerticalM,
    width: "100%",
  },
  header: {
    display: "none",
  },
  chatMessageContainer: {
    alignSelf: "flex-end",
    maxWidth: "75%",

    paddingBlock: tokens.spacingVerticalS,
    paddingInline: tokens.spacingHorizontalM,
    borderRadius: tokens.borderRadiusMedium,
    backgroundColor: tokens.colorBrandBackground,
  },
  chatMessage: {
    color: tokens.colorBrandBackgroundInverted,
  },
  chatResponseContainer: {
    maxWidth: "80%",
    backgroundColor: tokens.colorNeutralBackground1Hover,
    paddingBlock: tokens.spacingVerticalS,
    paddingInline: tokens.spacingHorizontalM,
    borderRadius: tokens.borderRadiusMedium,
  },
});

export const ChatItem = (chatProps: Props) => {
  const {
    isLoading,
    isSummarizing,
    question,
    answer,
    searchResults,
    error,
    isReferencesOpen,
    setIsReferencesOpen,
    typewrite,
  } = chatProps;

  useEffect(() => {
    console.log("Chat props", chatProps);
  }, [chatProps]);

  const sanitizedAnswer = useMemo(() => {
    if (answer && !isLoading) {
      const reorderedAnswer = searchResults ? reorderCitations(answer) : answer;
      return isReferencesOpen ? markDownCitations(reorderedAnswer) : removeCitations(reorderedAnswer);
    }

    return "";
  }, [answer, searchResults, isReferencesOpen, isLoading]);

  const typewriterText = useTypewriter({ text: sanitizedAnswer, delay: 2 });

  const elipsisText = useTypewriter({ text: "...", delay: 300, infinite: true });

  const { onRetry } = useSearchContext();
  const styles = useStyles();

  let content;

  if (error) {
    content = (
      <div className="chatMessageContainer chatMessageContainer--error">
        <div className="chatMessage">
          <div>
            <div>
              <span>Error</span>
            </div>

            <Text>{error}</Text>
          </div>

          <Button color="primary" onClick={() => onRetry()}>
            Try again
          </Button>
        </div>
      </div>
    );
  } else if (isLoading) {
    content = (
      <div className="chatMessageContainer chatMessageContainer--thinking">
        <div className="chatMessage">🧠 Thinking{elipsisText}</div>
      </div>
    );
  } else if (answer) {
    const reorderedSearchResults = (searchResults ? applyCitationOrder(searchResults, answer) : []).slice(
      0,
      SUMMARY_NUM_RESULTS,
    );

    content = (
      <div className="">
        <div className="chatMessage">
          <Text>
            <Markdown
              options={{
                forceInline: true,
                overrides: {
                  SummaryCitation: {
                    component: SummaryCitation,
                  },
                },
              }}
            >
              {typewrite ? typewriterText : sanitizedAnswer}
            </Markdown>
          </Text>

          {reorderedSearchResults && reorderedSearchResults.length > 0 && (
            <>
              <ChatReferences
                searchResults={reorderedSearchResults}
                isOpen={isReferencesOpen}
                setIsOpen={setIsReferencesOpen}
              />
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.chatItem}>
      <div className={styles.chatMessageContainer}>
        <div className={styles.chatMessage}>{question}</div>
      </div>

      <div className={styles.chatResponseContainer}>{content}</div>
    </div>
  );
};
