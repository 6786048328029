import { useEffect, useState } from "react";

export const useTypewriter = ({
  text,
  delay,
  infinite = false,
}: {
  text: string;
  delay: number;
  infinite?: boolean;
}) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let timeout;

    if (currentIndex <= text.length && text.length > 0) {
      timeout = setTimeout(() => {
        setCurrentText((prevText) => {
          const currentChar = typeof text[currentIndex] !== "undefined" ? text[currentIndex] : "";
          console.log("Previous text: ", { prevText, text, currentIndex, currentChar });
          return typeof prevText !== "undefined" ? prevText + currentChar : currentChar;
        });
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);
    } else if (infinite) {
      // ADD THIS CHECK
      setCurrentIndex(0);
      setCurrentText("");
    }

    return () => clearTimeout(timeout);
  }, [currentIndex, delay, infinite, text]);

  return currentText;
};
