import {
  Button,
  Caption1,
  Card,
  CardHeader,
  Divider,
  Spinner,
  Text,
  Tooltip,
  makeStyles,
  mergeClasses,
  tokens,
} from "@fluentui/react-components";
import {
  ArrowSyncCircleRegular,
  ChevronDownRegular,
  ChevronUpRegular,
  Eye12Regular,
  InfoRegular,
  WarningFilled,
} from "@fluentui/react-icons";
import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import TabLayout from "../components/TabLayout";
import { conductRiskAnalysis } from "../lib/riskAnalysis";
import { documentState } from "../state/documentState";
import { FeatureAccessBanner } from "../components/FeatureAccessBanner";
import { FEATURE_IDS } from "../config/features";
import { useInitialAnalysis } from "../hooks/useInitialAnalysis";

type RiskItem = {
  Party: string;
  Clause: string;
  Section: string;
  Risk: string;
  "Market Standard": string;
  "Risk Rating": "Low" | "Medium" | "High";
};

/* global Word */

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: tokens.spacingVerticalM,
    flex: "1 100%",
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 100%",
  },
});

interface RiskItemInterface {
  id: number;
  title: string;
  items: RiskItem[];
}

const RISK_ICONS = {
  Low: InfoRegular,
  Medium: WarningFilled,
  High: WarningFilled,
};

const useRiskCardStyles = makeStyles({
  card: {
    cursor: "pointer",
    userSelect: "none",
  },
  cardActive: {
    backgroundColor: tokens.colorNeutralBackground1Hover,
    ["& .fui-CardHeader__description"]: {
      display: "none",
    },
  },
  header: {},
  caption: {},
  captionActive: {
    display: "none",
  },
  description: {
    display: "none",
  },
  descriptionActive: {
    display: "block",
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: tokens.spacingHorizontalM,
  },
  cardTitle: {
    display: "block",
    marginBottom: tokens.spacingVerticalM,
  },
  descriptionText: {
    display: "block",
    marginBlockStart: tokens.spacingVerticalS,
    marginBlockEnd: tokens.spacingVerticalXL,
  },
  // example: {
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   justifyItems: "center",
  //   minHeight: "96px",
  //   backgroundColor: tokens.colorNeutralBackground1,
  // },
});

const FILL_COLORS = {
  High: tokens.colorStatusDangerBorder2,
  Medium: tokens.colorStatusWarningBorder2,
  Low: undefined,
};

const ErrorLevels = {
  High: "High Risk",
  Medium: "Medium Risk",
  Low: "Low Risk",
};

const RiskCardItem: React.FC<{ item: RiskItem }> = ({ item }) => {
  const styles = useRiskCardStyles();

  const Icon = RISK_ICONS[item["Risk Rating"]];
  const fill = FILL_COLORS[item["Risk Rating"]];
  const level = ErrorLevels[item["Risk Rating"]];

  const [expanded, setExpanded] = useState(false);

  const truncatedDescription = item.Risk && item.Risk.length > 60 ? `${item.Risk.slice(0, 60)}...` : item.Risk;

  const comment = async (text: string, comment: string) => {
    await Word.run(async (context) => {
      const searchResults = context.document.body.search(text.substring(0, 254), {
        ignorePunct: true,
        ignoreSpace: true,
        matchCase: false,
      });

      searchResults.load();
      await context.sync();

      for (let i = 0; i < searchResults.items.length; i++) {
        searchResults.items[i].insertComment(comment);
        searchResults.items[i].select();
      }

      await context.sync();
    });
  };

  return (
    <Card
      key={item.Clause}
      appearance="filled-alternative"
      className={mergeClasses(styles.card, expanded && styles.cardActive)}
      size="small"
    >
      <CardHeader
        image={{
          as: "div",
          children: (
            <Tooltip showDelay={0} content={level} relationship="label">
              <Icon primaryFill={fill} style={{ height: 24, width: 24 }} />
            </Tooltip>
          ),
        }}
        onClick={() => (truncatedDescription ? setExpanded(!expanded) : null)}
        description={
          truncatedDescription ? (
            <Caption1 className={expanded ? styles.captionActive : styles.caption}>{truncatedDescription}</Caption1>
          ) : undefined
        }
        action={
          truncatedDescription ? (
            <Button appearance="transparent" icon={expanded ? <ChevronUpRegular /> : <ChevronDownRegular />} />
          ) : undefined
        }
        header={<Text weight="semibold">{item.Clause}</Text>}
      />
      <div className={expanded ? styles.descriptionActive : styles.description}>
        {/* <Text>Risk</Text> */}
        <Divider alignContent="start">{level}</Divider>

        <Text className={styles.descriptionText}>{item.Risk}</Text>

        <Divider alignContent="start">Market Standard</Divider>

        <Text className={styles.descriptionText}>{item["Market Standard"]}</Text>

        <Button onClick={() => comment(item.Section, item.Risk)} icon={<Eye12Regular />}>
          View In Document
        </Button>
      </div>
    </Card>
  );
};

const RiskCard: React.FC<RiskItemInterface> = (props) => {
  const { items, title } = props;
  const styles = useRiskCardStyles();

  return (
    <div>
      <Text className={styles.cardTitle}>{title}</Text>
      <div className={styles.cardWrapper}>
        {items.map((item) => {
          return <RiskCardItem key={item.Clause} item={item} />;
        })}
      </div>
      <Divider style={{ marginTop: tokens.spacingVerticalL }} />
    </div>
  );
};

const Risk: React.FC = () => {
  const styles = useStyles();
  const { shouldAnalyze, hasFeatureAccess, loadingFeatureAccess, initialLoadComplete, completeInitialLoad } =
    useInitialAnalysis(FEATURE_IDS.RISK_ANALYSIS);

  const [loading, setLoading] = useState(false);
  const [, setError] = useState<string | null>(null);
  const [{ lastUploaded }] = useRecoilState(documentState);
  const [data, setData] = useState<any[]>([]);
  const [summary] = useState<string | null>(null);

  const summarizeDocument = useCallback(
    async (regenerate?: boolean) => {
      setLoading(true);
      try {
        const response = await conductRiskAnalysis("", lastUploaded !== null || regenerate);
        setData(response);
      } catch (error: any) {
        console.log("Error summarizing document", error);
        setError(error.message);
      } finally {
        setLoading(false);
        completeInitialLoad();
      }
    },
    [lastUploaded, completeInitialLoad],
  );

  useEffect(() => {
    if (shouldAnalyze) {
      summarizeDocument();
    }
  }, [shouldAnalyze, summarizeDocument]);

  const items = Object.entries(data).map(([title, value], index) => ({
    id: index,
    title,
    items: value as unknown as RiskItem[],
  }));

  return (
    <TabLayout>
      <div className={styles.wrapper}>
        {loading ? (
          <div className={styles.loader}>
            <Spinner />
          </div>
        ) : (
          <>
            {summary}
            {items.map((item) => (
              <RiskCard key={item.id} {...item} />
            ))}
            {!loadingFeatureAccess && !hasFeatureAccess && (
              <FeatureAccessBanner featureName="Risk Analysis" subscriptionLevel="Basic" />
            )}
            {initialLoadComplete && hasFeatureAccess && (
              <Card className={styles.header}>
                <Button
                  onClick={() => summarizeDocument(true)}
                  icon={<ArrowSyncCircleRegular />}
                  disabled={!hasFeatureAccess}
                >
                  Re-analyze
                </Button>
              </Card>
            )}
          </>
        )}
      </div>
    </TabLayout>
  );
};

export default Risk;
