import * as React from "react";
import { Button, Field, ProgressBar, Textarea, Tooltip, makeStyles, tokens } from "@fluentui/react-components";
import { useChatContext } from "../context/ChatContext";
import { ArrowDownRegular, ArrowSyncCircleRegular, SendFilled } from "@fluentui/react-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { z } from "zod";
import { ChatItem } from "../components/ChatItem";
import TabLayout from "../components/TabLayout";
import { useConfigContext } from "../context/ConfigurationContext";
import { useSearchContext } from "../context/SearchContext";
import { useMount } from "../hooks/useMount";
import { deleteDocument, setupDocumentSync, uploadDocument } from "../lib/vectaraHelpers";
import { getFileProperties, getTextFromDocument } from "../lib/wordHelpers";
import { documentState } from "../state/documentState";
import { FeatureAccessBanner } from "../components/FeatureAccessBanner";
import { useFeatureAccess } from "../hooks/useFeatureAccess";
import { FEATURE_IDS } from "../config/features";

/* global */

const useStyles = makeStyles({
  page: {
    padding: "0 15px",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  viewport: {
    display: "flex",
    flexDirection: "column",
    flex: "1 100%",
    paddingBottom: "15px",
  },
  root: {
    paddingBottom: "20px",
  },
  tablist: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    rowGap: "20px",
    marginBottom: "15px",
    // borderBottom: `1px solid ${tokens.colorNeutralStroke1}`,
  },
  example: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyItems: "center",
    minHeight: "96px",
    backgroundColor: tokens.colorNeutralBackground1,
    width: "100%",
    flex: "1 100%",
  },
  messages: {},
  messageArea: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    alignItems: "flex-start",
    overflowY: "scroll",
    rowGap: tokens.spacingVerticalM,
    marginBottom: tokens.spacingVerticalM,
    flex: "1 100%",
    "&::-webkit-scrollbar": {
      display: "none",
    },

    // overflowY: "scroll",
    // height: "100%",
  },
  messageAreaEmpty: {
    // flex: "1 100%",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    alignItems: "flex-start",
    overflowY: "scroll",
    rowGap: tokens.spacingVerticalM,
    marginBottom: tokens.spacingVerticalM,
    flex: "1 100%",
    justifyContent: "flex-end",
    // overflowY: "scroll",
    // height: "100%",
  },
  messageInput: {},
  prompts: {
    display: "flex",
    gap: "6px",
    marginBottom: "15px",
    flexWrap: "wrap",
  },
  promptsParagraph: {
    margin: "0 0 10px",
  },

  header: {
    display: "none",
  },
  mine: {
    alignSelf: "flex-end",
    maxWidth: "75%",
    padding: "6px 10px",
    borderRadius: tokens.borderRadiusMedium,
    backgroundColor: tokens.colorBrandBackground,
  },
  mineHeader: {
    display: "none",
  },
  mineP: {
    display: "inline",
    margin: "0",
    color: tokens.colorNeutralForeground1,
  },
  theirs: {
    maxWidth: "75%",
  },
  theirsBody: {
    paddingBlock: tokens.spacingVerticalM,
    paddingInline: tokens.spacingHorizontalM,
    backgroundColor: tokens.colorNeutralBackground1Hover,
    borderRadius: tokens.borderRadiusMedium,
  },
  theirsHeader: {
    display: "none",
    alignItems: "center",
    marginBottom: tokens.spacingVerticalS,
    marginTop: "-40px",
    marginLeft: "-20px",
  },
  theirsMessaging: {
    display: "none",
    flexDirection: "column",
    lineHeight: "1em",
    marginLeft: tokens.spacingHorizontalS,
  },
  theirsLogo: {
    width: "45px",
    height: "45px",
    borderRadius: tokens.borderRadiusCircular,
  },
  theirsP: {
    display: "inline",
    margin: "0",
  },
  insights: {
    display: "flex",
    flexDirection: "column",
    rowGap: tokens.spacingVerticalL,
  },
  insightsButton: {
    width: "100%",
    justifyContent: "flex-start",
  },
  form: {
    // position: "sticky",
    // bottom: 0,
    // backgroundColor: tokens.colorNeutralBackground1,
    // paddingBlock: tokens.spacingVerticalM,
  },
  fieldWrapper: {
    position: "relative",
  },
  buttonWrapper: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    bottom: tokens.spacingVerticalM,
    right: tokens.spacingVerticalM,
    gap: tokens.spacingHorizontalSNudge,
  },
  formSubmit: {},
  textArea: {
    paddingRight: "82px",
  },
  progressBar: {
    marginTop: tokens.spacingVerticalM,
  },
  scrollToBottomButton: {
    position: "absolute",
    bottom: "80px",
    right: "50%",
    transform: "translateX(50%)",
    zIndex: 1000,
  },
});

const chatSchema = z.object({
  input: z.string().min(1, { message: "Required" }),
});

type ChatSchemaType = z.infer<typeof chatSchema>;

const Chat: React.FC = () => {
  const styles = useStyles();
  const {
    isSearching,
    isSummarizing,

    searchError,
    searchResults,
    summarizationError,
    summarizationResponse,
    summarizationQuestion,
  } = useSearchContext();
  const { search } = useConfigContext();
  const { onSubmitChat, chatHistory } = useChatContext();
  const [isReferencesOpen, setIsReferencesOpen] = useState(false);
  const [{ documentId, lastUploaded, isSyncing }, setDocumentState] = useRecoilState(documentState);

  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState("Syncing document...");
  const [progressVisible, setProgressVisible] = useState(false);

  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const appLayoutRef = useRef<HTMLDivElement>(null);
  const isScrolledToBottomRef = useRef(true);

  const updateScrollPosition = () => {
    // Scrolling UX rules:
    // * Scroll down if the last recorded scroll position was already
    //   at the bottom of the list and if the last question has resolved
    //   to an answer.
    // * If the user has scrolled to another position, then don't
    //   auto-scroll.

    // This way if the user takes control of the scroll position, they
    // remain in control. If the user hasn't taken control of the scroll
    // position, then the scroll feels stable (by staying at the
    // bottom) as opposed to scrolling unpredictably through the list
    // as questions resolve.
    if (isScrolledToBottomRef.current) {
      // Scroll to the bottom of the chat to keep the latest turn in view.
      appLayoutRef.current?.scrollTo({
        left: 0,
        top: appLayoutRef.current?.scrollHeight,
        behavior: "smooth",
      });
    }
  };
  const scrollToBottom = () => {
    appLayoutRef.current?.scrollTo({
      top: appLayoutRef.current.scrollHeight,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const layoutNode = appLayoutRef.current;

    const onScrollContent = () => {
      if (appLayoutRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = appLayoutRef.current;
        const isScrolledToBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 30;
        isScrolledToBottomRef.current = isScrolledToBottom;

        // Show scroll-to-bottom button if scrolled up more than 1 page
        setShowScrollToBottom(!isScrolledToBottom && scrollTop < scrollHeight - 2 * clientHeight);
      }
    };

    layoutNode?.addEventListener("scroll", onScrollContent);

    return () => {
      layoutNode?.removeEventListener("scroll", onScrollContent);
    };
  }, []);

  useEffect(updateScrollPosition, [
    isSearching,
    isSummarizing,
    searchError,
    summarizationError,
    summarizationResponse,
    summarizationQuestion,
    chatHistory,
    isReferencesOpen,
  ]);

  const chatItems = chatHistory.map((message, index) => <ChatItem key={index} {...message} />);

  if (isSearching || isSummarizing || summarizationResponse || searchError || summarizationError) {
    chatItems.push(
      <ChatItem
        isLoading={isSearching || !summarizationResponse}
        isSummarizing={isSummarizing}
        question={summarizationQuestion}
        answer={summarizationResponse}
        searchResults={searchResults}
        error={searchError && <>{searchError.message}</>}
        isReferencesOpen={isReferencesOpen}
        setIsReferencesOpen={setIsReferencesOpen}
        typewrite
      />,
    );
  }

  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<ChatSchemaType>({
    resolver: zodResolver(chatSchema),
    defaultValues: {
      input: "",
    },
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  const onSubmit = async (data: z.infer<typeof chatSchema>) => {
    onSubmitChat(data.input);
    reset();
  };

  const syncDocument = useCallback(
    async (force = false) => {
      if (!documentId || (lastUploaded && !force)) return null;
      setDocumentState((prev) => ({ ...prev, isSyncing: true }));
      window.documentTitle = (await getFileProperties()).title;
      const documentText = await getTextFromDocument();
      const documentProperties = await getFileProperties();
      const corpusKey = "";
      const apiKey = "";
      const corpusType = "chat";
      try {
        await setupDocumentSync();
      } catch (exception) {
        console.log("unable to setupDocumentSync");
      }
      try {
        await deleteDocument(documentProperties.title, documentProperties, corpusKey, apiKey, corpusType);
      } catch (error) {
        console.error("Error deleting document: " + error);
      }
      try {
        if (documentText.trim().length == 0) {
          return;
        }
        try {
          await uploadDocument(
            documentProperties.title,
            documentText,
            documentProperties,
            corpusKey,
            apiKey,
            corpusType,
          );
        } catch (error) {
          console.error(`Error uploading document: ${error}`);
        }
      } catch (exception) {
        console.log("unable to upload document");
      }

      setDocumentState((prev) => ({ ...prev, lastUploaded: new Date(), isSyncing: false }));
    },
    [setDocumentState, documentId, lastUploaded],
  );

  useEffect(() => {
    if (isSyncing) {
      setProgressMessage("Syncing document...");
      setProgressVisible(true);
      setProgress(0.35);
    } else {
      setProgress(1);
      setProgressMessage("Sync complete!");
      setTimeout(() => {
        setProgress(0);
        setProgressVisible(false);
      }, 1500);
    }
  }, [isSyncing]);

  const { hasFeatureAccess, loadingFeatureAccess } = useFeatureAccess(FEATURE_IDS.CHAT);

  useEffect(() => {
    if (!loadingFeatureAccess && hasFeatureAccess) {
      syncDocument();
    }
  }, [loadingFeatureAccess, hasFeatureAccess, syncDocument]);

  return (
    <TabLayout fixed>
      <div ref={appLayoutRef} className={chatItems.length > 0 ? styles.messageArea : styles.messageAreaEmpty}>
        {chatItems.map((message, index) => (
          <React.Fragment key={index}>{message}</React.Fragment>
        ))}
        {showScrollToBottom && (
          <Tooltip withArrow content="Scroll to bottom" relationship="description">
            <Button
              className={styles.scrollToBottomButton}
              icon={<ArrowDownRegular />}
              onClick={scrollToBottom}
              aria-label="Scroll to bottom"
            />
          </Tooltip>
        )}
      </div>
      {!loadingFeatureAccess && !hasFeatureAccess && (
        <FeatureAccessBanner featureName="Chat" subscriptionLevel="Basic" />
      )}
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Field validationState={errors.input?.message ? "error" : "none"} className={styles.fieldWrapper}>
          <Controller
            control={control}
            name="input"
            render={({ field }) => (
              <Textarea
                size="medium"
                rows={undefined}
                placeholder="What's your question"
                className={styles.textArea}
                onKeyDown={handleKeyDown}
                onChange={field.onChange}
                value={field.value}
                onBlur={field.onBlur}
                disabled={!loadingFeatureAccess && !hasFeatureAccess}
              />
            )}
          />

          <div className={styles.buttonWrapper}>
            <Tooltip withArrow content="Re-sync Document" relationship="description">
              <Button
                onClick={() => syncDocument(true)}
                className={styles.formSubmit}
                icon={<ArrowSyncCircleRegular />}
                disabled={!loadingFeatureAccess && !hasFeatureAccess}
              />
            </Tooltip>
            <Tooltip withArrow content="Submit" relationship="label">
              <Button type="submit" className={styles.formSubmit} icon={<SendFilled />} disabled={!hasFeatureAccess} />
            </Tooltip>
          </div>
        </Field>

        {progressVisible && (
          <Field validationMessage={progressMessage} validationState="none">
            <ProgressBar className={styles.progressBar} value={progress} />
          </Field>
        )}
      </form>
    </TabLayout>
  );
};

export default Chat;
