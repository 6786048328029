import { CompoundButton, makeStyles, tokens } from "@fluentui/react-components";
import { DocumentBulletListFilled } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import TabLayout from "./TabLayout";

export const AI_AGENTS = [
  {
    title: "Lease Abstractor",
    description: "Generate a lease abstract from a lease document.",
    icon: DocumentBulletListFilled,
    to: "/ai-agents/lease-abstractor",
  },
  {
    title: "NVCA Assistant",
    description: "Populate NVCA COI from a fully negotiated term sheet.",
    icon: DocumentBulletListFilled,
    to: "/ai-agents/nvca-assistant",
  },
];

const useStyles = makeStyles({
  insights: {
    display: "flex",
    flexDirection: "column",
    rowGap: tokens.spacingVerticalL,
  },
  insightsButton: {
    width: "100%",
    justifyContent: "flex-start",
  },
});

const AiAgents = () => {
  const styles = useStyles();

  const navigate = useNavigate();

  return (
    <TabLayout>
      <div className={styles.insights}>
        {AI_AGENTS.map((prompt, index) => (
          <CompoundButton
            className={styles.insightsButton}
            onClick={() => navigate(prompt.to)}
            key={index}
            icon={<prompt.icon />}
            secondaryContent={prompt.description}
          >
            {prompt.title}
          </CompoundButton>
        ))}
      </div>
    </TabLayout>
  );
};

export default AiAgents;
