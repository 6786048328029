import React from "react";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { useCheckout } from "../context/CheckoutContext";
import { Button } from "@fluentui/react-components";
import { ChevronLeftRegular } from "@fluentui/react-icons";

const useStyles = makeStyles({
  pricingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start", // Change from "center" to "flex-start"
    alignItems: "stretch", // Change from "center" to "stretch"
    gap: tokens.spacingVerticalM, // Reduce the gap
    padding: tokens.spacingVerticalM, // Reduce padding
    fontFamily: tokens.fontFamilyBase,
    minHeight: "100vh",
    boxSizing: "border-box",
    width: "100%",
    maxWidth: "400px",
    margin: "0 auto",
    backgroundColor: tokens.colorNeutralBackground1,
    color: tokens.colorNeutralForeground1,
  },
  pricingCard: {
    width: "100%",
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke1),
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
    padding: tokens.spacingVerticalL,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: tokens.colorNeutralBackground1,
    transition: "all 0.3s ease",
    "&:hover": {
      boxShadow: tokens.shadow8,
    },
    "@media (max-width: 768px)": {
      width: "100%",
      maxWidth: "300px",
    },
  },
  mostPopular: {
    backgroundColor: tokens.colorNeutralBackground2,
    transform: "scale(1.05)",
    ...shorthands.border("1px", "solid", tokens.colorNeutralStrokeAccessible),
    boxShadow: tokens.shadow8,
    "@media (max-width: 768px)": {
      transform: "scale(1)",
    },
  },
  title: {
    fontSize: tokens.fontSizeBase600,
    fontWeight: tokens.fontWeightSemibold,
    margin: tokens.spacingVerticalS,
  },
  price: {
    fontSize: tokens.fontSizeHero900,
    fontWeight: tokens.fontWeightSemibold,
    margin: tokens.spacingVerticalS,
  },
  perMonth: {
    fontSize: tokens.fontSizeBase200,
    color: tokens.colorNeutralForeground2,
  },
  subscribeButton: {
    backgroundColor: tokens.colorBrandBackground,
    color: tokens.colorNeutralForegroundOnBrand,
    ...shorthands.border("none"),
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
    padding: `${tokens.spacingVerticalS} ${tokens.spacingHorizontalL}`,
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightSemibold,
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    marginTop: tokens.spacingVerticalM,
    "&:hover": {
      backgroundColor: tokens.colorBrandBackgroundHover,
    },
  },
  featureList: {
    textAlign: "left",
    listStyleType: "none",
    padding: 0,
    margin: `${tokens.spacingVerticalM} 0`,
    width: "100%",
    lineHeight: tokens.lineHeightBase200,
  },
  featureItem: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: tokens.spacingVerticalXS,
    fontSize: tokens.fontSizeBase200,
    color: tokens.colorNeutralForeground1,
  },
  checkmark: {
    marginRight: tokens.spacingHorizontalS,
    color: tokens.colorBrandBackground,
    fontSize: tokens.fontSizeBase200,
  },
  mostPopularLabel: {
    position: "absolute",
    top: tokens.spacingVerticalS,
    right: tokens.spacingHorizontalS,
    backgroundColor: tokens.colorNeutralBackground3,
    color: tokens.colorNeutralForeground1,
    padding: `${tokens.spacingVerticalXXS} ${tokens.spacingHorizontalXS}`,
    borderRadius: tokens.borderRadiusSmall,
    fontSize: tokens.fontSizeBase100,
    fontWeight: tokens.fontWeightSemibold,
    ...shorthands.border("1px", "solid", tokens.colorNeutralStroke1),
  },
  description: {
    fontSize: tokens.fontSizeBase200,
    color: tokens.colorNeutralForeground2,
    marginBottom: tokens.spacingVerticalXS,
    lineHeight: tokens.lineHeightBase200,
  },
  contactButton: {
    backgroundColor: tokens.colorNeutralBackground1,
    color: tokens.colorBrandForeground1,
    ...shorthands.border("1px", "solid", tokens.colorBrandForeground1),
    ...shorthands.borderRadius(tokens.borderRadiusMedium),
    padding: `${tokens.spacingVerticalS} ${tokens.spacingHorizontalL}`,
    fontSize: tokens.fontSizeBase300,
    fontWeight: tokens.fontWeightSemibold,
    cursor: "pointer",
    transition: "all 0.3s ease",
    marginTop: tokens.spacingVerticalM,
    "&:hover": {
      backgroundColor: tokens.colorBrandBackground,
      color: tokens.colorNeutralForegroundOnBrand,
    },
  },
  breadcrumb: {
    display: "flex",
    alignItems: "center",
    fontSize: tokens.fontSizeBase300,
    color: tokens.colorNeutralForeground1,
    cursor: "pointer",
    marginBottom: tokens.spacingVerticalM, // Add margin to separate from pricing cards
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

interface PricingTierProps {
  title: string;
  price: number | string;
  isPopular?: boolean;
  onSubscribe: () => void;
  onContactUs: () => void;
  priceId?: string;
  marketingFeatures: string[];
  description: string;
  isContactUs?: boolean;
}

const PricingTier: React.FC<PricingTierProps> = ({
  title,
  price,
  isPopular = false,
  onSubscribe,
  onContactUs,
  marketingFeatures,
  description,
  isContactUs = false,
}) => {
  const styles = useStyles();

  return (
    <div className={`${styles.pricingCard} ${isPopular ? styles.mostPopular : ""}`}>
      {isPopular && <div className={styles.mostPopularLabel}>Most popular</div>}
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.description}>{description}</p>
      <div className={styles.price}>
        {typeof price === "number" ? (
          <>
            ${price}
            <span className={styles.perMonth}> per month</span>
          </>
        ) : (
          price
        )}
      </div>
      {isContactUs ? (
        <button className={styles.contactButton} onClick={onContactUs}>
          Contact Us
        </button>
      ) : (
        <button className={styles.subscribeButton} onClick={onSubscribe}>
          Subscribe
        </button>
      )}
      <div className={styles.featureList}>
        <p>This includes:</p>
        {marketingFeatures.map((feature, index) => (
          <div key={index} className={styles.featureItem}>
            <span className={styles.checkmark}>&#10004;</span>
            {feature}
          </div>
        ))}
      </div>
    </div>
  );
};

const pricingTiers = [
  {
    title: "Basic",
    price: 99,
    isPopular: false,
    priceId: process.env.BASIC_PRICE_ID || "price_1Pwc3D2KYymxNn9FWzhslMKd",
    marketingFeatures: ["Chatbot Functionality", "Unlimited Reviews of Any Length", "Core Contract Analysis Features"],
    description: "Perfect for small teams or individual users",
  },
  {
    title: "Pro",
    price: 149,
    isPopular: false,
    marketingFeatures: [
      "Unlimited Reviews of Any Length",
      "Chatbot Functionality",
      "Core Contract Analysis Features",
      "Basic Knowledge Base Integrations",
    ],
    description: "Ideal for growing businesses with advanced needs",
  },
  {
    title: "Enterprise",
    isPopular: false,
    marketingFeatures: [
      "Unlimited Reviews of Any Length",
      "Chatbot Functionality",
      "Core Contract Analysis Features",
      "Advanced Knowledge Base Integrations",
      "Custom Tailored AI Agents",
      "Unlimited Custom Functions",
      "Dedicated Support and Consultation Hours",
    ],
    description: "Tailored solutions for large organizations",
  },
];

const CustomPricingTable: React.FC<{ history?: string[] }> = ({ history }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { setPriceId } = useCheckout();

  const handleSubscribe = (tier: string, priceId?: string) => {
    if (priceId) {
      console.log(`Setting priceId for ${tier} tier`);
      setPriceId(priceId);
      navigate("/checkout", { state: { history: [...history, "/stripe"] } });
    }
  };

  const handleContactUs = (tier: string) => {
    const subject = `Inquiry about ${tier} Plan`;
    const body = `Hello,

I'm interested in learning more about the ${tier} plan for LexIQ. Could you please provide me with more information about:

1. The specific features included in this plan
2. Pricing details
3. The process for upgrading from my current plan

Thank you for your assistance.

Best regards,
[Your Name]`;

    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);
    window.location.href = `mailto:samarth.gupta@lexiq.io?subject=${encodedSubject}&body=${encodedBody}`;
  };

  const handleBack = () => {
    if (history && history.length > 0) {
      const previousPage = history.pop();
      navigate(previousPage || "/root", { state: { history } });
    } else {
      navigate("/root");
    }
  };

  return (
    <div className={styles.pricingContainer}>
      <Button appearance="subtle" icon={<ChevronLeftRegular />} onClick={handleBack} className={styles.breadcrumb}>
        Back
      </Button>
      {pricingTiers.map((tier, index) => (
        <PricingTier
          key={index}
          title={tier.title}
          price={tier.price}
          isPopular={tier.isPopular}
          priceId={tier.priceId}
          marketingFeatures={tier.marketingFeatures}
          description={tier.description}
          onSubscribe={() => handleSubscribe(tier.title, tier.priceId)}
          onContactUs={() => handleContactUs(tier.title)}
          isContactUs={tier.title !== "Basic"}
        />
      ))}
    </div>
  );
};

export default CustomPricingTable;
