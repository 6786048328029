import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Field,
  Input,
  makeStyles,
  Spinner,
  tokens,
} from "@fluentui/react-components";
import { Mail16Regular } from "@fluentui/react-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { sendEmail } from "../lib/emailHelper";

const formSchema = z.object({
  input: z.string().min(1, { message: "Required" }),
});

type FormType = z.infer<typeof formSchema>;

const useStyles = makeStyles({
  spinner: {
    marginLeft: tokens.spacingHorizontalSNudge,
  },
});

export const EmailToDialog = ({ contents }: { contents: string }) => {
  const [open, setOpen] = useState(false);
  const [sendingText, setSendingText] = useState("Send");
  const styles = useStyles();

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<FormType>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (data: FormType) => {
    setSendingText("Sending...");
    await sendEmail("Lease Abstraction", contents, data.input);
    setSendingText("Sent!");

    setTimeout(() => {
      setSendingText("Send");
      setOpen(false);
    }, 2000);
  };

  console.log("Email dialog contents", contents);
  return (
    <Dialog
      open={open}
      onOpenChange={(_e, data) => {
        setOpen(data.open);
      }}
    >
      <DialogTrigger disableButtonEnhancement>
        <Button icon={<Mail16Regular />}>Email to someone else</Button>
      </DialogTrigger>
      <DialogSurface>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogBody>
            <DialogTitle>Send Via Email</DialogTitle>
            <DialogContent>
              <Field
                validationState={errors.input?.message ? "error" : "none"}
                validationMessage={errors.input?.message}
              >
                <Input placeholder="Email address" {...register("input")} />
              </Field>
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button disabled={isSubmitting} type="button" appearance="secondary">
                  Cancel
                </Button>
              </DialogTrigger>
              <Button disabled={isSubmitting} onClick={handleSubmit(onSubmit)} appearance="primary">
                {sendingText} {isSubmitting && <Spinner className={styles.spinner} size="extra-tiny" />}
              </Button>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  );
};
