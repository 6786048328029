import {
  Button,
  MessageBar,
  MessageBarActions,
  MessageBarBody,
  MessageBarTitle,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
  },
  text: {
    marginBlock: tokens.spacingVerticalM,
  },
});

interface MessageInterface {
  title: string;
  text?: string;
  dismissable?: boolean;
}

export const Message = (props: MessageInterface) => {
  const { title = "Did you know?", text = "Message body", dismissable = false } = props;
  const styles = useStyles();

  return (
    <MessageBar className={styles.root} layout="multiline">
      <MessageBarBody className={styles.root}>
        <MessageBarTitle>{title}</MessageBarTitle>
        {text && <p className={styles.text}>{text}</p>}
      </MessageBarBody>
      {dismissable && (
        <MessageBarActions
          containerAction={<Button aria-label="dismiss" appearance="transparent" icon={<DismissRegular />} />}
        />
      )}
    </MessageBar>
  );
};
