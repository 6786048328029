/* global */

import { openAiQuery } from "./openai";

export async function onAskClick(question: string) {
  console.log("Ask Button clicked");

  try {
    const result = await openAiQuery(question);

    const lines = result
      .split("\n")
      .map((line: string) => line.trim())
      .filter((line: string) => line.length > 0);

    return lines;
  } catch (error) {
    console.error("Error calling OpenAI:", error);
  } 
}
