import axios from "axios";

import { ChatResponse, SummaryLanguage, mmrRerankerId } from "../types/view";
import { END_TAG, START_TAG } from "../helpers/parseSnippet";
import { getFileProperties } from "../lib/wordHelpers";
import { CookieNames, getCookie } from "../lib/cookies";

/* global OfficeRuntime*/

type Config = {
  filter: string;
  queryValue?: string;
  language?: SummaryLanguage;
  summaryMode?: boolean;
  rerank?: boolean;
  rerankNumResults?: number;
  rerankerId?: number;
  rerankDiversityBias?: number;
  hybridNumWords: number;
  hybridLambdaShort?: number;
  hybridLambdaLong?: number;
  summaryNumResults?: number;
  summaryNumSentences?: number;
  summaryPromptName?: string;
  customerId: string;
  corpusId: string;
  endpoint: string;
  apiKey: string;
  chat?: {
    conversationId?: string;
  };
};

export const sendSearchRequest = async ({
  filter,
  queryValue,
  language,
  summaryMode,
  rerank,
  rerankNumResults,
  rerankerId,
  rerankDiversityBias,
  hybridNumWords,
  hybridLambdaShort,
  hybridLambdaLong,
  summaryNumResults,
  summaryNumSentences,
  summaryPromptName,
  customerId,
  corpusId,
  endpoint,
  apiKey,
  chat,
}: Config) => {
  console.log("filter: " + filter);
  const augmentedFilter: string = "doc.id='" + window.documentTitle + "'";
  const lambda =
    typeof queryValue === "undefined" || queryValue.trim().split(" ").length > hybridNumWords
      ? hybridLambdaLong
      : hybridLambdaShort;
  // Commenting these out because i was trying to get the v1 to work with v2 but i just made a v2 version of everything instead.
  // const corpusKeyList = corpusId.split(",").map((id) => {
  //   return {
  //     customerId: Number(customerId),
  //     corpusId: Number(id),
  //     lexicalInterpolationConfig: {
  //       lambda: lambda,
  //     },
  //     //metadataFilter: filter ? filter : `doc.id = '${window.documentTitle}'`,
  //     metadataFilter: augmentedFilter,
  //   };
  // });
  const corpusKeyList = [
    {
      customerId: Number(customerId),
      corpusId: Number(corpusId), // Assuming corpusId is a string representing a single ID
      lexicalInterpolationConfig: {
        lambda: lambda,
      },
      //metadataFilter: filter? filter : `doc.id = '${window.documentTitle}`,
      metadataFilter: augmentedFilter,
    },
  ];

  const body = {
    query: [
      {
        query: queryValue,
        start: 0,
        numResults: rerank ? rerankNumResults : 5,
        corpusKey: corpusKeyList,
        contextConfig: {
          sentencesBefore: summaryMode ? summaryNumSentences : 2,
          sentencesAfter: summaryMode ? summaryNumSentences : 2,
          startTag: START_TAG,
          endTag: END_TAG,
        },
        summary: [
          {
            responseLang: language ? language : "en",
            maxSummarizedResults: summaryNumResults ? summaryNumResults : 10,
            summarizerPromptName: summaryPromptName ? summaryPromptName : "vectara-summary-ext-24-05-large",
            chat: {
              store: true,
              conversationId: chat?.conversationId,
            },
          },
        ],
        ...(rerank
          ? {
              rerankingConfig: {
                rerankerId: rerankerId,
                ...(rerankerId === mmrRerankerId
                  ? {
                      mmrConfig: {
                        diversityBias: rerankDiversityBias,
                      },
                    }
                  : {}),
              },
            }
          : {}),
      },
    ],
  };

  const url = `https://${endpoint}/v1/query`;
  const headers = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "customer-id": customerId,
      "x-api-key": apiKey,
      "grpc-timeout": "60S",
    },
  };
  console.log("Search request body: " + JSON.stringify(body));
  console.log("Search request headers: " + JSON.stringify(headers));

  const result = await axios.post(url, body, headers);

  console.log("My result:\n" + JSON.stringify(result));

  const status = result["data"]["responseSet"][0]["status"];
  if (status.length > 0 && status[0]["code"] === "UNAUTHORIZED") {
    console.log("UNAUTHORIZED access; check your API key and customer ID");
  }

  if (summaryMode) {
    const summaryStatus = result["data"]["responseSet"][0]["summary"][0]["status"];
    if (summaryStatus.length > 0 && summaryStatus[0]["code"] === "BAD_REQUEST") {
      throw new Error(
        `BAD REQUEST: Too much text for the summarizer to summarize. Please try reducing the number of search results to summarize, or the context of each result by adjusting the 'summary_num_sentences', and 'summary_num_results' parameters respectively.`,
      );
    }
    if (
      summaryStatus.length > 0 &&
      summaryStatus[0]["code"] === "NOT_FOUND" &&
      summaryStatus[0]["statusDetail"] === "Failed to retrieve summarizer."
    ) {
      throw new Error(`BAD REQUEST: summarizer ${summaryPromptName} is invalid for this account.`);
    }
  }

  return result["data"]["responseSet"][0];
};

type ConfigV2 = {
  filter?: string;
  queryValue?: string;
  language?: SummaryLanguage;
  rerank?: boolean;
  rerankNumResults?: number;
  rerankerId?: string;
  rerankDiversityBias?: number;
  hybridNumWords?: number;
  hybridLambdaShort?: number;
  hybridLambdaLong?: number;
  summaryNumResults?: number;
  summaryNumSentences?: number;
  summaryPromptName?: string;
  customerId?: string;
  corpusId: string;
  endpoint: string;
  apiKey: string;
  chat?: {
    conversationId?: string;
  };
  streamResponse: boolean;
};

export const sendSearchRequestV2 = async ({
  filter,
  queryValue,
  language,
  rerank,
  rerankNumResults,
  rerankerId,
  // Taking diversity bias out since i couldn't get it to get a valid request body on the v2 query api.
  // rerankDiversityBias,
  hybridNumWords,
  hybridLambdaShort,
  hybridLambdaLong,
  summaryNumResults,
  summaryNumSentences,
  summaryPromptName,
  corpusId,
  endpoint,
  apiKey,
}: // chat,
ConfigV2) => {
  console.log("filter: " + filter);
  const augmentedFilter: string = "doc.id='" + window.documentTitle + "'";
  const lambda =
    typeof queryValue === "undefined" || queryValue.trim().split(" ").length > (hybridNumWords ?? 0)
      ? hybridLambdaLong
      : hybridLambdaShort;

  const body = {
    query: queryValue,
    search: {
      corpora: [
        {
          custom_dimensions: {},
          metadata_filter: augmentedFilter,
          lexical_interpolation: lambda,
          semantics: "default",
          corpus_key: corpusId,
        },
      ],
      offset: 0,
      limit: rerank ? rerankNumResults : 5,
      context_configuration: {
        characters_before: 0,
        characters_after: 0,
        sentences_before: summaryNumSentences || 2,
        sentences_after: summaryNumSentences || 2,
        start_tag: START_TAG,
        end_tag: END_TAG,
      },
      ...(rerank && {
        reranker: {
          type: "customer_reranker",
          reranker_id: rerankerId,
          // Taking diversity bias out since i couldn't get it to get a valid request body on the v2 query api.
          // ...(rerankDiversityBias && { diversity_bias: rerankDiversityBias }),
        },
      }),
    },
    generation: {
      prompt_name: summaryPromptName || "vectara-summary-ext-v1.2.0",
      max_used_search_results: summaryNumResults || 10,
      max_response_characters: 300,
      response_language: language || "en",
      model_parameters: {
        max_tokens: 0,
        temperature: 0,
        frequency_penalty: 0,
        presence_penalty: 0,
      },
      citations: {
        style: "none",
        url_pattern: "https://vectara.com/documents/{doc.id}",
        text_pattern: "{doc.title}",
      },
      enable_factual_consistency_score: false,
    },
    stream_response: false,
  };

  // Commented out because v2 query api doesn't have this.
  // if (chat?.conversationId) {
  //   body.generation.chat = {
  //     store: true,
  //     conversation_id: chat.conversationId,
  //   };
  // }

  const url = `https://${endpoint}/v2/query`;
  const headers = {
    "Content-Type": "application/json",
    // For whatever reason prettier wants to not make "Accept" a string and takes out quotes. I don't think that's intended.

    Accept: "application/json",
    "x-api-key": apiKey,
  };

  console.log("Search request body: " + JSON.stringify(body));
  console.log("Search request headers: " + JSON.stringify(headers));

  try {
    const result = await axios.post(url, body, { headers });
    console.log("My result:\n" + JSON.stringify(result.data));

    // Error handling
    if (result.status !== 200) {
      throw new Error(`HTTP error! status: ${result.status}`);
    }

    // Check for specific error conditions in the response
    if (result.data.error) {
      throw new Error(`API error: ${result.data.error}`);
    }

    return result.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        console.log("UNAUTHORIZED access; check your API key and customer ID");
        throw new Error("Unauthorized: Invalid API key or customer ID");
      }
      if (error.response?.status === 400) {
        throw new Error(`Bad Request: ${error.response.data.message || "Unknown error"}`);
      }
    }
    throw error;
  }
};

// Commenting out most of the previous parameters just so that we can start playing with minimally viable search request sent. We can probably add these parameters later to adjust the quality of response from chat.
export const sendSearchRequestWithV2Chat = async ({
  // filter,
  queryValue,
  // language,
  // rerank,
  // rerankNumResults,
  // rerankerId,
  // Taking diversity bias out since i couldn't get it to get a valid request body on the v2 query api.
  // rerankDiversityBias,
  // hybridNumWords,
  // hybridLambdaShort,
  // hybridLambdaLong,
  // summaryNumResults,
  // summaryNumSentences,
  // summaryPromptName,
  corpusId,
  endpoint,
  apiKey,
  streamResponse,
}: // chat,
ConfigV2): Promise<ChatResponse> => {
  // Most of the query's schema comes from playing with teh query editor in vectara's console. E.g.: https://console.vectara.com/console/corpus/22/query?inspector=request

  const augmentedFilter: string = "doc.id='" + (await getFileProperties()).title + "'";
  const body = {
    query: queryValue,
    search: {
      corpora: [
        {
          corpus_key: corpusId,
          metadata_filter: augmentedFilter,
          lexical_interpolation: 0.005,
          custom_dimensions: {},
        },
      ],
      offset: 0,
      limit: 15,
      // These tags get added to the result text so if we don't need these tags we can just not include them in the query.
      // context_configuration: {
      //   start_tag: START_TAG,
      //   end_tag: END_TAG,
      // },
    },
    generation: {
      // prompt_name: "vectara-summary-ext-v1.2.0",
      // Seems like we rarely use more than 10 search results even if we have chunks of 1000 chars.
      max_used_search_results: 15,
      // prompt_name: "mockingbird-1.0-2024-07-16",
      // Omni is better for summarizing without getting too many too much text to summarize issues with other prompt name models.
      // prompt_name: "vectara-summary-ext-24-05-med-omni",
      prompt_name: "vectara-summary-ext-v1.2.0",
      prompt_text: "",
      response_language: "eng",
      enable_factual_consistency_score: false,
    },
    chat: {
      store: true,
    },
    stream_response: streamResponse,
  };

  const url = `https://${endpoint}/v2/chats`;
  const bearerToken = await getCookie(
    CookieNames.Token,
    async () => {
      return await OfficeRuntime.auth.getAccessToken({
        allowSignInPrompt: false,
        allowConsentPrompt: false,
        forMSGraphAccess: false,
      });
    },
    1 / 24,
  );
  const headers = {
    Authorization: `Bearer ${bearerToken}`,
    "Content-Type": "application/json",
    "x-api-key": apiKey,
  };
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: url,
    headers: headers,
    data: JSON.stringify(body),
  };

  try {
    const result = await axios(config);

    // Error handling
    if (result.status !== 200) {
      throw new Error(`HTTP error! status: ${result.status}`);
    }

    // Check for specific error conditions in the response
    if (result.data.error) {
      throw new Error(`API error: ${result.data.error}`);
    }

    return result.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        console.log("UNAUTHORIZED access; check your API key and customer ID");
        throw new Error("Unauthorized: Invalid API key or customer ID");
      }
      if (error.response?.status === 400) {
        throw new Error(`Bad Request: ${error.response.data.message || "Unknown error"}`);
      }
    }
    throw error;
  }
};
