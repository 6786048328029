/* global */

import { openAiQuery } from "./openai";
import { MultiQuery } from "./vectaraHelpers";
//import { getFileProperties } from "./wordHelpers";

export interface Filler {
  Category: string;
  Subject: string;
  Location: string;
  Value: string;
}

function chunkArray(array: Filler[], chunkSize: number): Filler[][] {
  const chunks: Filler[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

export const conductFillinNVCATemplate = async (referenceDocumentText: string, blanks: Filler[]) => {
  //const _documentId = (await getFileProperties()).title;
  console.log("Conducting NVCA Fill in.");
  //const refDocId = _documentId + "_ref";

  const chunkedBlanks: Filler[][] = chunkArray(blanks, 10);
  const queries: MultiQuery[] = chunkedBlanks.map((blanks) => {
    return {
      query: `Give the list of these subjects, ${blanks.map((b) => b.Subject).join(", ")}, find the values from the reference text and return to me in JSON format, Subject & Value. Do not change the wording. If you don't find the value, return 'NOT FOUND' in the value.`,
      /*prompt: `[
    {
      "role": "system",
      "content": "You are a legal expert that will review the reference document text and fill in the value for the subject user is looking for.
      You will return 'Subject' and 'Value' in the JSON format. The subject word should be exact as given in the query. For example, subject would be 'qualified_period'

      These are only subject keywords that are allowed. Do not change the wording. [${blanks.map((b) => b.Subject).join(", ")}]

      These are all the subject that needs the value. Fill in the Value from the termsheet. There will be some values that can't be found from the reference document, then just return 'NOT FOUND' for those. 
      '[
{
  Subject: 'SUBJECT',
  Value: ''
}
]'
      "
    }
]`,*/
      prompt: `You are a legal expert that will review the reference document text and fill in the value for the subject user is looking for.
      You will return 'Subject' and 'Value' in the JSON format. The subject word should be exact as given in the query. For example, subject would be 'qualified_period'

      These are only subject keywords that are allowed. Do not change the wording.
      As context, I've given you the blanks it would fill in based on the subject.
      Make sure the value makes sense into the sentence when replacing the blanks. Meaning if there is a dollar sign in front of blanks, $[___], value should not have dollar sign.
      If there is a text within the blank, such as [___ times] then the value you should include all the text within the brackets, meaning the value should look like '5 times'
      The values are never too long, so keep it as concise as possible.
      
      [${blanks
        .map((b) => {
          return `Subject: ${b.Subject} Sentence: ${b.Location}`;
        })
        .join(", ")}]

      These are all the subject that needs the value. Fill in the Value from the termsheet. There will be some values that can't be found from the reference document, then just return 'NOT FOUND' for those. 
      '[
{
  Subject: 'SUBJECT',
  Value: ''
}
]'
      `,
    };
  });

  const response = await Promise.all(
    queries.map(async (queryText) => {
      const response = await openAiQuery(queryText.query, queryText.prompt, `reference text: ${referenceDocumentText}`);
      return response;
    }),
  );

  //var response = await conductMultiQuery(refDocId, referenceDocumentText, queries);

  console.log("Response", response);
  const results = response.reduce((acc, res) => {
    let temp = res.replace(/[\s\S]*(\[[\r\n\s]*\{[\s\S]*\}[\r\n\s]*\])[\s\S]*/g, "$1"); // remove all text before first [
    try {
      const responseJson = JSON.parse(temp);
      return acc.concat(responseJson);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return acc;
    }
  }, []);

  blanks.forEach((blank) => {
    const found = results.find((res) => res["Subject"] === blank.Subject);
    if (found) {
      blank.Value = found["Value"];
    } else {
      blank.Value = "NOT FOUND";
      console.log("Not found for", blank.Subject);
    }
  });

  return blanks;
};
