/* global OfficeRuntime */

import { CookieNames, getCookie } from "../lib/cookies";

const BASE_URL = process.env.BACKEND_API_URL;

export const middlewareRequest = async <T>(
  method: "POST" | "GET" | "DELETE" | "PUT" | "PATCH",
  path: string,
  requestBody?: any,
  additionalHeaders?: { [key: string]: string },
): Promise<T> => {
  let token: string = await getCookie(
    CookieNames.Token,
    async () => {
      return await OfficeRuntime.auth.getAccessToken({
        allowSignInPrompt: false,
        allowConsentPrompt: false,
        forMSGraphAccess: false,
      });
    },
    1 / 24,
  );

  console.log("Calling middleware with token");
  console.log("Request path: " + path);
  // eslint-disable-next-line no-undef
  const options: RequestInit = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      ...additionalHeaders,
    },
  };

  if (requestBody) {
    options.body = JSON.stringify(requestBody);
  }
  const optionsWithSignal = {
    ...options,
  };

  const response = await fetch(BASE_URL + path, optionsWithSignal);
  // const response = await fetch(path, optionsWithSignal);
  console.log("Response: " + JSON.stringify(response));

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  // convert to response
  try {
    if (response) {
      const data = await response.json();
      return data as T; // todo
    }
  } catch (error) {
    console.error("Error during middleware request: " + error);
    return undefined as T;
  }
};
