import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { FluentProvider, webDarkTheme, webLightTheme } from "@fluentui/react-components";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { loadStripe } from "@stripe/stripe-js";

// Initialize Stripe
const stripePromise = loadStripe(process.env.STRIPE_KEY || "");

interface OfficeThemeExtended extends Office.OfficeTheme {
  isDarkTheme: boolean;
}

initializeIcons();

/* global Office */

let isDarkMode = false;
// Interface for App component props
interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
  isDarkMode: boolean;
}

const render = (props: AppProps) => {
  createRoot(document.getElementById("container") as HTMLElement).render(
    <React.StrictMode>
      <FluentProvider theme={isDarkMode ? webDarkTheme : webLightTheme}>
        <App {...props} stripePromise={stripePromise} />
      </FluentProvider>
    </React.StrictMode>,
  );
};

// eslint-disable-next-line office-addins/no-office-initialize
Office.initialize = function () {};

(async () => {
  await Office.onReady().then(function (info) {
    const theme = Office.context.officeTheme as OfficeThemeExtended;
    isDarkMode = typeof theme.isDarkTheme !== "undefined" ? theme.isDarkTheme : theme.bodyForegroundColor === "#FFFFFF";
    render({ title: "LexIQ Add-in", isOfficeInitialized: true, isDarkMode });
    console.log(`Office.js is now ready in ${info.host} on ${info.platform}`);
  });
})();
