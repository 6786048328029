import { EffectCallback, useEffect, useRef } from "react";

export function useMount(effect: EffectCallback) {
  useEffect(() => {
    effect();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}

/**
 *
 * See this blog post for more information:
 * https://taig.medium.com/prevent-react-from-triggering-useeffect-twice-307a475714d7
 *
 * TODO: Refactor fetches to use a more proper technique for fetching data (or utilize something like react-query)
 *
 * See this StackOverflow: https://stackoverflow.com/questions/72238175/why-useeffect-running-twice-and-how-to-handle-it-well-in-react
 *
 * @param effect
 */
export function useOnMountUnsafe(effect: EffectCallback) {
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      effect();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}
