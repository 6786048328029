import { Body1, Button, Card, CardFooter, CardHeader, Spinner, makeStyles, tokens } from "@fluentui/react-components";
import { ArrowSyncCircleRegular } from "@fluentui/react-icons";
import React, { useState, useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { summarizeDates } from "../lib/summarize";
import { documentState } from "../state/documentState";
import TabLayout from "../components/TabLayout";
import Markdown from "markdown-to-jsx";
import { FeatureAccessBanner } from "../components/FeatureAccessBanner";
import { FEATURE_IDS } from "../config/features";
import { useInitialAnalysis } from "../hooks/useInitialAnalysis";

const useStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralBackground1Hover,
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 100%",
  },
});

const SummarizeDates: React.FC = () => {
  const styles = useStyles();
  const { shouldAnalyze, hasFeatureAccess, loadingFeatureAccess, initialLoadComplete, completeInitialLoad } =
    useInitialAnalysis(FEATURE_IDS.SUMMARIZE_DATES);

  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [, setReferences] = useState<{ text: string; value: string }[]>([]);
  const [{ documentId, lastUploaded }, setDocumentState] = useRecoilState(documentState);

  const callSumarizeDates = useCallback(
    async (regenerate?: boolean) => {
      if (loading || (!regenerate && summary)) return;

      setLoading(true);
      try {
        if (!documentId) {
          return;
        }
        const response = await summarizeDates(documentId, lastUploaded === null || regenerate);
        console.log("Response", response);
        if (response?.summaryResult) {
          setSummary(response.summaryResult);
        }
        if (response?.references) {
          setReferences(response.references as { text: string; value: string }[]);
        }
        setDocumentState((prev) => ({ ...prev, lastUploaded: new Date() }));
      } catch (error: any) {
        console.log("Error summarizing document", error);
        setError(error.message);
      } finally {
        setLoading(false);
        completeInitialLoad();
      }
    },
    [documentId, loading, summary, setDocumentState, setSummary, setReferences, setError, completeInitialLoad],
  );

  useEffect(() => {
    if (shouldAnalyze) {
      callSumarizeDates();
    }
  }, [shouldAnalyze, callSumarizeDates]);

  useEffect(() => {
    console.log("error", error);
  }, [error]);

  return (
    <TabLayout>
      {!loadingFeatureAccess && !hasFeatureAccess && (
        <FeatureAccessBanner featureName="Date Summarization" subscriptionLevel="Basic" />
      )}
      {loading ? (
        <div className={styles.loader}>
          <Spinner />
        </div>
      ) : (
        <>
          {!summary && initialLoadComplete && hasFeatureAccess && (
            <Button onClick={() => callSumarizeDates()} icon={<ArrowSyncCircleRegular />} disabled={!hasFeatureAccess}>
              Summarize Dates
            </Button>
          )}
          {summary ? (
            <Card className={styles.root}>
              <CardHeader
                header={
                  <Body1>
                    <b>Summary</b>
                  </Body1>
                }
              />
              <Markdown>{summary}</Markdown>
              {initialLoadComplete && hasFeatureAccess && (
                <CardFooter className={styles.header}>
                  <Button
                    onClick={() => callSumarizeDates(true)}
                    icon={<ArrowSyncCircleRegular />}
                    disabled={!hasFeatureAccess}
                  >
                    Re-analyze
                  </Button>
                </CardFooter>
              )}
            </Card>
          ) : null}
        </>
      )}
    </TabLayout>
  );
};

export default SummarizeDates;
