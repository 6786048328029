/* global */

import { conductAnalyzeV2, VectaraQueryApiRequest } from "./vectaraHelpers";
import { getFileProperties } from "./wordHelpers";

export async function conductRiskAnalysis(documentId: string, upload = false) {
  console.log("Conducting Risk Analysis");

  const queryText = `What are specific clauses and conditions that could be considered non-standard or particularly risky for either of the two parties involved?`;

  const promptText = `
  [
   {
       "role": "system",
       "content": "You are a legal expert that understands contractual agreement documents. You are being asked the following question about a contract document between two parties. Base the answer only on the following information."
   },
   #foreach ($qResult in $vectaraQueryResults)
   #if ($foreach.first)
       {"role": "user", "content": "Search for '\${vectaraQuery}', and give me the first search result."},
       {"role": "assistant", "content": "\${qResult.getText()}" },
   #else
       {"role": "user", "content": "Give me the $vectaraIdxWord[$foreach.index] search result."},
       {"role": "assistant", "content": "$qResult.docMetadata().get('answerDate') \${qResult.getText()}" },
   #end
   #end
   {
       "role": "user",
       "content": "Generate a comprehensive and informative answer for the question '$esc.java(\${vectaraQuery})' solely based on the search results in this chat. You must only use information from the provided results. For each identified clause or condition, include the following:
1. Which party the risk pertains to.
2. A brief description of the clause/condition.
3. The original section text where the clause/condition is located. Limit the character length to 254.
4. Why it is considered risky or non-standard.
5. What is typically considered standard or 'market' for that clause/condition.
6. An overall 'Risk Rating' of 'Low', 'Medium', or 'High' based on similar kinds of agreements that have been executed in the past.
Format the response into two sections: 'Non-Standard or Risky Provisions for [first party]' and 'Non-Standard or Risky Provisions for [second party].' Please always refer to either of the two parties by their full respective names. Structure the output in a neat parseable JSON schema like this:

{
    'Non-Standard or Risky Provisions for ABCD Enterprises (Landlord)': [
      {
        'Party': 'ABCD Enterprises (Landlord)',
        'Clause': 'Indemnification',
        'Section': 'We are not responsible for any damages caused by the tenant's negligence',
        'Risk': 'The landlord's indemnification clause limits liability for damages caused by tenant's negligence. This could lead to legal disputes over responsibility for damages',
        'Market Standard': 'Typically, landlords are not indemnified for their own negligence, ensuring accountability',
        'Risk Rating': 'Medium
      }
}

Do not include line breaks in the JSON.

Do not include any escape characters before or after the JSON.

For the section field do not change the original text

Do not put punctuation at the end of the value fields of the JSON.

Do not copy any party names from the JSON example, just use the JSON example for general formatting and structure purposes. Ignore the number of risk examples listed in the JSON for each party.  If you find more, list them. If you believe there are less, that is ok too.
"
   }
]`;

  const docTitle = (await getFileProperties()).title;
  let metadataFilterVal: string;
  if (documentId) {
    metadataFilterVal = "doc.id = " + `'${documentId}'`;
  } else {
    metadataFilterVal = "doc.id = " + `'${docTitle}'`;
  }
  const request: VectaraQueryApiRequest = {
    query: queryText,
    numResults: 20,
    search: {
      corpora: [
        {
          // Corpus key is injected server side.
          corpus_key: "",
          metadata_filter: metadataFilterVal,
          lexical_interpolation: 0.1,
          semantics: "default",
        },
      ],
      context_configuration: {
        sentences_after: 1,
        sentences_before: 2,
        start_tag: "<b>",
        end_tag: "</b>",
      },
    },
    generation: {
      // Seems like we rarely use more than 10 search results even if we have chunks of 1000 chars.
      max_used_search_results: 30,
      //prompt_name: "mockingbird-1.0-2024-07-16",
      prompt_name: "vectara-summary-ext-24-05-med-omni",
      prompt_text: promptText,
      enable_factual_consistency_score: false,
      response_language: "eng",
      model_parameters: {
        max_tokens: 2048,
        temperature: 0.1,
        frequency_penalty: 0,
        presence_penalty: 0,
      },
    },
  };
  let response = await conductAnalyzeV2(documentId, upload, request);
  console.log("Response", response);
  var jsonVal = response?.summaryResult;
  console.log("Response Summary Result", response.summaryResult);
  const cleanJSON = jsonVal
    .replace(/^"/, "") // Remove leading quote
    .replace(/"$/, "") // Remove trailing quote
    .replace(/'/g, '"') // Replace single quotes with double quotes
    .replace(/\\"/g, '"') // Fix escaped quotes
    .replace(/\\/g, ""); // Remove remaining backslashes
  console.log("Regex parsed response", cleanJSON);
  const responseJson = JSON.parse(cleanJSON);

  return responseJson;
}
