import { conductAnalyze, conductAnalyzeV2, VectaraQueryApiRequest } from "./vectaraHelpers";
import { getFileProperties } from "./wordHelpers";

export async function conductLeaseAbstraction(documentId: string, upload = true) {
  const queryText = `Identify important amounts, dates, and information from the lease agreement.`;

  const promptText = `
  
  [
   {
       "role": "system",
       "content": "You are a legal expert that understands contractual agreement documents. You are being asked the following question about a contract document between two parties. Base the answer only on the following information."
   },
   #foreach ($qResult in $vectaraQueryResults)
   #if ($foreach.first)
       {"role": "user", "content": "Search for '\${vectaraQuery}', and give me the first search result."},
       {"role": "assistant", "content": "\${qResult.getText()}" },
   #else
       {"role": "user", "content": "Give me the $vectaraIdxWord[$foreach.index] search result."},
       {"role": "assistant", "content": "$qResult.docMetadata().get('answerDate') \${qResult.getText()}" },
   #end
   #end
   {
       "role": "user",
       "content": "Generate a comprehensive and informative answer for the question '$esc.java(\${vectaraQuery})' solely based on the search results in this chat. You must only use information from the provided results. For each identified clause or condition, include the following:
1. Tenant Name: The name of the tenant, as stated in the lease agreement.
2. Rent Amount: The amount of rent that the tenant is required to pay, including any rent escalations.
3. Square Footage: The size of the leased premises.
4. Dates
   a. Date the lease agreement was signed.
   b. Lease term commencement date.
   c. Date the tenant is required to start paying rent.
   d. Lease term expiration date.
5. Lease Term: The defined lease term, including specific start and end dates.
6. Leased Premises: A legal definition of the exact space the tenant is permitted to occupy.
7. Permitted Uses: The activities allowed on the premises, including any restrictions against illegal activities.
8. Parking Requirements: The number of parking spaces provided to the tenant as part of the lease agreement.
9. Repairs: Responsibility for making and paying for repairs.
10. Co-Tenancy: Whether the lease includes co-tenancy provisions.
11. Subleasing: Whether the tenant is allowed to sublease all or part of their space to another party.
12. Expansion Rights: Rights for the tenant to lease additional space in the same building if it becomes available.
13. Assignability and Exclusivity: Whether the lease is assignable to another party and any exclusivity clauses.
14 Estoppel: Any estoppel provisions included in the lease.

Please always refer to the parties by their full respective names. Structure the output in a neat numbered HTML list like this:
Do not include any text before or after the HTML list.
<ol>
    <li><strong>Tenant Name</strong>: ABCD Enterprises</li>
    <li><strong>Rent Amount</strong>:
        <ol>
            <li>Months 1–2: Rent Abated</li>
            <li>Months 3–12: $12,423.00</li>
            <li>Months 13–24: $12,795.69</li>
            <li>Months 25–36: $13,179.56</li>
            <li>Months 37–38: $13,574.95​(CA11_test).</li>
        </ol>
    </li>
</ol>      
"
   }
]`;

  try {
    const docTitle = (await getFileProperties()).title;
    let metadataFilterVal: string;
    if (documentId) {
      metadataFilterVal = "doc.id = " + `'${documentId}'`;
    } else {
      metadataFilterVal = "doc.id = " + `'${docTitle}'`;
    }
    console.log("metadataFilterVal", metadataFilterVal);
    const request: VectaraQueryApiRequest = {
      query: queryText,
      numResults: 20,
      search: {
        corpora: [
          {
            // Corpus key is injected server side.
            corpus_key: "",
            metadata_filter: metadataFilterVal,
            lexical_interpolation: 0.1,
            semantics: "default",
          },
        ],
        context_configuration: {
          sentences_after: 2,
          sentences_before: 2,
          start_tag: "<b>",
          end_tag: "</b>",
        },
      },
      generation: {
        // Seems like we rarely use more than 10 search results even if we have chunks of 1000 chars.
        max_used_search_results: 20,
        // prompt_name: "mockingbird-1.0-2024-07-16",
        prompt_name: "vectara-summary-ext-24-05-large",
        prompt_text: promptText,
        enable_factual_consistency_score: false,
        response_language: "eng",
        model_parameters: {
          max_tokens: 2048,
          temperature: 0.1,
          frequency_penalty: 0.5,
          presence_penalty: 0,
        },
      },
    };
    let response = await conductAnalyzeV2(documentId, upload, request);
    return response.summaryResult;
  } catch (error) {
    console.error("Error summarizing document", error);
    throw error;
  }
  // try {
  //   var response = await conductAnalyze(_documentId, queryText, upload, promptText, modelName);
  //   return response.summaryResult;
  // } catch (error) {
  //   console.error("Error summarizing document", error);
  //   throw error;
  // }
}
