import * as React from "react";

import {
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbDivider,
  BreadcrumbItem,
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  TabValue,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { ChatMultipleFilled } from "@fluentui/react-icons";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AI_AGENTS } from "./AiAgents";
import { PROMPT_DETAILED } from "./DocumentTools";

/* global */

const useStyles = makeStyles({
  page: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  pageFixed: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  viewport: {
    display: "flex",
    flexDirection: "column",
    flex: "1 100%",
    paddingBlock: tokens.spacingVerticalM,
    paddingInline: tokens.spacingHorizontalM,
  },
  viewportFixed: {
    display: "flex",
    flexDirection: "column",
    flex: "1 100%",
    paddingBlock: tokens.spacingVerticalM,
    paddingInline: tokens.spacingHorizontalM,
    height: "100%",
    overflow: "hidden",
  },
  tablistWrapper: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    position: "sticky",
    top: 0,
    backgroundColor: tokens.colorNeutralBackground1,
  },
  tablist: {
    display: "flex",
    alignItems: "center",
  },
});

const TabLayout: React.FC<{ children: React.ReactNode; fixed?: boolean }> = (props) => {
  const styles = useStyles();
  const [selectedValue, setSelectedValue] = useState<TabValue>("tools");

  const location = useLocation();

  const navigate = useNavigate();

  const onTabSelect = (_event: SelectTabEvent, data: SelectTabData) => {
    navigate(data.value as string);
  };

  useEffect(() => {
    if (location.pathname.includes("chat")) {
      setSelectedValue("/chat");
    } else if (location.pathname.includes("analyze")) {
      setSelectedValue("/analyze");
    } else if (location.pathname.includes("ai-agents")) {
      setSelectedValue("/ai-agents");
    }
  }, [location]);

  const currentTool = useMemo(() => {
    const currentPath = location.pathname;
    return PROMPT_DETAILED.find((tool) => tool.to === currentPath);
  }, [location.pathname]);

  const currentAgent = useMemo(() => {
    const currentPath = location.pathname;
    return AI_AGENTS.find((tool) => tool.to === currentPath);
  }, [location.pathname]);

  return (
    <>
      <div className={props.fixed ? styles.pageFixed : styles.page}>
        <div className={styles.tablistWrapper}>
          <TabList className={styles.tablist} size="medium" selectedValue={selectedValue} onTabSelect={onTabSelect}>
            <Tab icon={<ChatMultipleFilled />} aria-label="First Tab" value="/chat">
              Chat
            </Tab>

            <Tab value="/analyze">Analyze</Tab>
            <Tab aria-label="AI Agents" value="/ai-agents">
              AI Agents
            </Tab>
          </TabList>
        </div>

        <div className={props.fixed ? styles.viewportFixed : styles.viewport}>{props.children}</div>
        {/* <div>
          {(currentTool || currentAgent) && (
            <Breadcrumb aria-label="">
              <BreadcrumbItem>
                <BreadcrumbButton
                  onClick={() => {
                    currentTool ? navigate("/analyze") : currentAgent && navigate("/ai-agents");
                  }}
                >
                  {currentTool ? "Analyze" : currentAgent ? "Agent" : ""}
                </BreadcrumbButton>
              </BreadcrumbItem>
              <BreadcrumbDivider />
              <BreadcrumbItem>
                <BreadcrumbButton current>{currentTool?.title ?? currentAgent?.title}</BreadcrumbButton>
              </BreadcrumbItem>
            </Breadcrumb>
          )}
        </div> */}
      </div>
    </>
  );
};

export default TabLayout;
