import { atom } from "recoil";

export interface DraftInterface {
  id: number;
  input: string;
  output: string;
}

export const draftState = atom<DraftInterface[]>({
  key: "draftState", // unique ID (with respect to other atoms/selectors)
  default: [],
});
