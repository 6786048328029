import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { middlewareRequest } from "../middle-tier/middlewareHelpers";
import { useCheckout } from "../context/CheckoutContext";
import ErrorModal from "./ErrorModal";
import { Spinner, Text, makeStyles } from "@fluentui/react-components";

interface SessionStatusResponse {
  status: "open" | "complete";
  customer_email: string;
}

const useStyles = makeStyles({
  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
    padding: "20px",
  },
  loadingText: {
    marginTop: "20px",
    fontSize: "16px",
    maxWidth: "400px",
  },
});

const Return = () => {
  const styles = useStyles();
  const [status, setStatus] = useState<null | "open" | "complete">(null);
  const [error, setError] = useState<string | null>(null);
  const { checkoutSessionId } = useCheckout();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (checkoutSessionId) {
      middlewareRequest("POST", "/session-status", { session_id: checkoutSessionId })
        .then((res) => {
          if (!res) {
            throw new Error(`HTTP error! status: ${res}`);
          }
          return res;
        })
        .then((data: SessionStatusResponse) => {
          setStatus(data.status);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching session status:", err);
          setError("An error occurred while processing your payment. Please try again.");
          setIsLoading(false);
        });
    }
  }, [checkoutSessionId]);

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Spinner size="large" />
        <Text className={styles.loadingText}>
          We&apos;re processing your payment and updating your subscription status. This may take up to 20 seconds.
          Please don&apos;t close this window.
        </Text>
      </div>
    );
  }

  if (error) {
    // can you adjust this so that the error modal appears and then redirects back to the pricing page
    return (
      <ErrorModal
        message={error}
        onClose={() => {
          setError(null);
        }}
        navigateTo="/stripe"
        navigateButtonText="Back to Pricing"
      />
    );
  }

  if (status === "open") {
    return <Navigate to="/checkout" />;
  }

  if (status === "complete") {
    return <Navigate to="/" />;
  }

  return null;
};

export default Return;
