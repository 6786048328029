import { middlewareRequest } from "../middle-tier/middlewareHelpers";

interface EmailRequest {
  subject: string;
  body: string;
  to?: string;
}

export const sendEmail = async (subject: string, body: string, to?: string): Promise<void> => {
  console.log("Sending email with subject: " + subject + " and body: " + body);
  await middlewareRequest("POST", "/email", { to, subject, body: body.replace(/ /g, "\u00a0") } as EmailRequest);
  console.log("Email sent");
};
