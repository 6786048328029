import React from "react";
import { Dialog, DialogType, DialogFooter } from "@fluentui/react/lib/Dialog";
import { PrimaryButton, DefaultButton } from "@fluentui/react/lib/Button";
import { useNavigate } from "react-router-dom";

interface ErrorModalProps {
  message: string;
  onClose: () => void;
  navigateTo?: string; // New prop for navigation
  navigateButtonText?: string; // Optional custom text for the navigation button
}

const ErrorModal: React.FC<ErrorModalProps> = ({ message, onClose, navigateTo, navigateButtonText = "Go Back" }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (navigateTo) {
      navigate(navigateTo);
    }
    onClose();
  };

  return (
    <Dialog
      hidden={false}
      onDismiss={onClose}
      dialogContentProps={{
        type: DialogType.largeHeader,
        title: "Error",
        subText: message,
      }}
      modalProps={{
        isBlocking: true,
        styles: { main: { maxWidth: 450 } },
      }}
    >
      <DialogFooter>
        {navigateTo && <DefaultButton onClick={handleNavigation} text={navigateButtonText} />}
        <PrimaryButton onClick={onClose} text="OK" />
      </DialogFooter>
    </Dialog>
  );
};

export default ErrorModal;
