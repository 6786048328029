import React from "react";
import { makeStyles, Text, Button, tokens } from "@fluentui/react-components";
import { useNavigate, useLocation } from "react-router-dom";

interface FeatureAccessBannerProps {
  featureName: string;
  subscriptionLevel: string;
}

const useStyles = makeStyles({
  banner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: tokens.spacingHorizontalM,
    backgroundColor: tokens.colorNeutralBackground2,
    borderRadius: tokens.borderRadiusMedium,
    marginBottom: tokens.spacingVerticalM,
  },
  subscribeButton: {
    backgroundColor: tokens.colorBrandBackground,
    color: tokens.colorNeutralForegroundOnBrand,
    border: "none",
    borderRadius: tokens.borderRadiusMedium,
    padding: `${tokens.spacingVerticalS} ${tokens.spacingHorizontalM}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: tokens.colorBrandBackgroundHover,
    },
  },
});

export const FeatureAccessBanner: React.FC<FeatureAccessBannerProps> = ({ featureName, subscriptionLevel }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubscribe = () => {
    navigate("/stripe", { state: { from: location.pathname, history: [location.pathname] } });
  };

  return (
    <div className={styles.banner}>
      <Text>
        Subscribe to {subscriptionLevel} for access to {featureName}.
      </Text>
      <Button className={styles.subscribeButton} onClick={handleSubscribe}>
        Subscribe
      </Button>
    </div>
  );
};
