import Cookies from "js-cookie";

// ttl is the time to live in days
export const setCookie = (name: CookieNames, value: string, ttl: number) => {
  const expirationDate = new Date(Date.now() + ttl * 24 * 60 * 60 * 1000);
  const options: Cookies.CookieAttributes = { expires: expirationDate };
  console.log("Setting cookie: " + name + " with expiration date: " + expirationDate);
  Cookies.set(name, value, options);
};

export const getCookie = async (name: CookieNames, renew: () => Promise<string>, ttl: number): Promise<string> => {
  const cookieValue = Cookies.get(name);
  const cookieExpiration = Cookies.get(`${name}_expiration`);

  if (cookieValue && cookieExpiration) {
    const expirationDate = new Date(cookieExpiration);
    if (expirationDate > new Date(Date.now())) {
      return cookieValue;
    }
  }
  console.log("Cookie not found, renewing");
  const newValue = await renew();
  const expirationDate = new Date(Date.now() + ttl * 24 * 60 * 60 * 1000);
  console.log("Setting cookie: " + name + " with expiration date: " + expirationDate);
  Cookies.set(name, newValue, { expires: expirationDate });
  return newValue;
};

export const removeCookie = (name: CookieNames) => {
  Cookies.remove(name);
};

export enum CookieNames {
  Token = "token",
  UserId = "userId",
}
