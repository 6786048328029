import { Body1, Button, Card, CardFooter, CardHeader, Spinner, makeStyles, tokens } from "@fluentui/react-components";
import { ArrowSyncCircleRegular, Mail16Regular } from "@fluentui/react-icons";
import Markdown from "markdown-to-jsx";
import React, { useCallback, useState } from "react";
import { useRecoilState } from "recoil";
import { FeatureAccessBanner } from "../components/FeatureAccessBanner";
import TabLayout from "../components/TabLayout";
import { FEATURE_IDS } from "../config/features";
import { useFeatureAccess } from "../hooks/useFeatureAccess";
import { useOnMountUnsafe } from "../hooks/useMount";
import { sendEmail } from "../lib/emailHelper";
import { conductLeaseAbstraction } from "../lib/leaseAbstractor";
import { documentState } from "../state/documentState";
/* global */

const useStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralBackground1Hover,
    flexShrink: 1,
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  loader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 100%",
  },
});

const LeaseAbstractor: React.FC = () => {
  const styles = useStyles();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { hasFeatureAccess, loadingFeatureAccess } = useFeatureAccess(FEATURE_IDS.SUMMARIZE_CONTRACT);
  const [{ documentId, lastUploaded }, setDocumentState] = useRecoilState(documentState);
  const [abstraction, setAbstraction] = useState<string | null>(null);

  const onSendEmail = () => {
    if (abstraction) sendEmail("Lease Abstraction", abstraction);
    else console.log("No summary to send");
  };

  const sendQuery = useCallback(
    async (regenerate?: boolean) => {
      setLoading(true);
      try {
        if (!documentId || (abstraction && !regenerate)) {
          return;
        }
        console.log("Generate lease abstraction...");
        const response = await conductLeaseAbstraction(documentId, lastUploaded === null || regenerate);
        console.log("Lease abstraction response", response);
        setAbstraction(response);
        setDocumentState((prev) => ({ ...prev, lastUploaded: new Date() }));
      } catch (error: any) {
        console.log("Error summarizing document", error);
        setError(error.message);
      }
      setLoading(false);
    },
    [documentId, abstraction, lastUploaded, setDocumentState],
  );

  useOnMountUnsafe(() => {
    console.log("On mount", hasFeatureAccess);
    if (hasFeatureAccess) {
      sendQuery();
    }
  });

  return (
    <TabLayout>
      {!loadingFeatureAccess && !hasFeatureAccess && (
        <FeatureAccessBanner featureName="Lease Abstractor" subscriptionLevel="Basic" />
      )}
      {loading ? (
        <div className={styles.loader}>
          <Spinner />
          <p>Generating lease abstraction, hang tight...</p>
        </div>
      ) : (
        <>
          {!abstraction && (
            <Button
              onClick={() => sendQuery()}
              icon={<ArrowSyncCircleRegular />}
              disabled={!hasFeatureAccess && !loadingFeatureAccess}
            >
              Generate Lease Abstraction
            </Button>
          )}
          {abstraction ? (
            <Card className={styles.root}>
              <CardHeader
                header={
                  <Body1>
                    <b>Abstraction</b>
                  </Body1>
                }
              />
              <Markdown>{abstraction}</Markdown>
              <CardFooter className={styles.header}>
                <Button
                  onClick={() => sendQuery(true)}
                  icon={<ArrowSyncCircleRegular />}
                  disabled={!hasFeatureAccess && !loadingFeatureAccess}
                >
                  Re-analyze
                </Button>
                {hasFeatureAccess && (
                  <Button onClick={onSendEmail} icon={<Mail16Regular />} hidden={!abstraction}>
                    Send abstraction to your email
                  </Button>
                )}
              </CardFooter>
            </Card>
          ) : null}
        </>
      )}
    </TabLayout>
  );
};

export default LeaseAbstractor;
