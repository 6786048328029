import React, { createContext, useContext, useState } from "react";
import { Stripe } from "@stripe/stripe-js";

interface CheckoutContextType {
  priceId: string | null;
  setPriceId: (id: string) => void;
  checkoutSessionId: string | null;
  setCheckoutSessionId: (id: string) => void;
  stripePromise: Promise<Stripe | null>;
}

const CheckoutContext = createContext<CheckoutContextType | undefined>(undefined);

export const CheckoutProvider: React.FC<{ children: React.ReactNode; stripePromise: Promise<Stripe | null> }> = ({
  children,
  stripePromise,
}) => {
  const [priceId, setPriceId] = useState<string | null>(null);
  const [checkoutSessionId, setCheckoutSessionId] = useState<string | null>(null);

  return (
    <CheckoutContext.Provider value={{ priceId, setPriceId, checkoutSessionId, setCheckoutSessionId, stripePromise }}>
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckout = () => {
  const context = useContext(CheckoutContext);
  if (!context) {
    throw new Error("useCheckout must be used within a CheckoutProvider");
  }
  return context;
};
