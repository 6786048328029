import { Body1, Button, Card, CardFooter, CardHeader, Spinner, makeStyles, tokens } from "@fluentui/react-components";
import { ArrowSyncCircleRegular, Mail16Regular } from "@fluentui/react-icons";
import Markdown from "markdown-to-jsx";
import React, { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { EmailToDialog } from "../components/EmailToDialog";
import { FeatureAccessBanner } from "../components/FeatureAccessBanner";
import TabLayout from "../components/TabLayout";
import { FEATURE_IDS } from "../config/features";
import { useInitialAnalysis } from "../hooks/useInitialAnalysis";
import { sendEmail } from "../lib/emailHelper";
import { conductContractSummary } from "../lib/summarize";
import { documentState } from "../state/documentState";

const useStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralBackground1Hover,
    flexShrink: 1,
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 100%",
  },
});

const SummarizeDocument: React.FC = () => {
  const styles = useStyles();
  const { shouldAnalyze, hasFeatureAccess, loadingFeatureAccess, initialLoadComplete, completeInitialLoad } =
    useInitialAnalysis(FEATURE_IDS.SUMMARIZE_CONTRACT);

  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [references, setReferences] = useState<{ text: string; value: string }[]>([]);
  const [{ documentId, lastUploaded }, setDocumentState] = useRecoilState(documentState);

  const onSendEmail = () => {
    if (summary) sendEmail("Summary of the Contract", summary);
    else console.log("No summary to send");
  };

  const summarizeDocument = useCallback(
    async (regenerate?: boolean) => {
      setLoading(true);
      try {
        if (!documentId || (summary && !regenerate)) {
          return;
        }
        const response = await conductContractSummary(documentId, lastUploaded === null || regenerate);
        console.log("Response", response);
        if (response) {
          setSummary(response);
        }
        // if (response?.references) {
        //   setReferences(response.references as { text: string; value: string }[]);
        // }
        setDocumentState((prev) => ({ ...prev, lastUploaded: new Date() }));
      } catch (error: any) {
        console.log("Error summarizing document", error);
        setError(error.message);
      } finally {
        setLoading(false);
        completeInitialLoad();
      }
    },
    [setSummary, setError, documentId, lastUploaded, setDocumentState, summary, completeInitialLoad],
  );

  useEffect(() => {
    if (shouldAnalyze) {
      summarizeDocument();
    }
  }, [shouldAnalyze, summarizeDocument]);

  useEffect(() => {
    console.log("references", references);
  }, [references]);

  useEffect(() => {
    console.log("error", error);
  }, [error]);

  return (
    <TabLayout>
      {!loadingFeatureAccess && !hasFeatureAccess && (
        <FeatureAccessBanner featureName="Document Summarization" subscriptionLevel="Basic" />
      )}
      {loading ? (
        <div className={styles.loader}>
          <Spinner />
        </div>
      ) : (
        <>
          {!summary && initialLoadComplete && hasFeatureAccess && (
            <Button onClick={() => summarizeDocument()} icon={<ArrowSyncCircleRegular />} disabled={!hasFeatureAccess}>
              Summarize Document
            </Button>
          )}
          {summary ? (
            <Card className={styles.root}>
              <CardHeader
                header={
                  <Body1>
                    <b>Summary</b>
                  </Body1>
                }
              />
              <Markdown>{summary}</Markdown>
              {initialLoadComplete && hasFeatureAccess && (
                <CardFooter className={styles.header}>
                  <Button
                    onClick={() => summarizeDocument(true)}
                    icon={<ArrowSyncCircleRegular />}
                    disabled={!hasFeatureAccess}
                  >
                    Re-analyze
                  </Button>
                  <Button onClick={onSendEmail} icon={<Mail16Regular />} hidden={!summary}>
                    Email to me
                  </Button>
                  <EmailToDialog contents={summary} />
                </CardFooter>
              )}
            </Card>
          ) : null}
        </>
      )}
    </TabLayout>
  );
};

export default SummarizeDocument;
