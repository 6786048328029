import React, { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";

import { HistoryItem, addHistoryItem, deleteHistory, retrieveHistory } from "./history";

import { ApiV2, streamQueryV2 } from "@vectara/stream-query-client";
import { SearchError, SummaryLanguage, ChatResponse } from "../types/view";
import { useConfigContext } from "./ConfigurationContext";
import { sendSearchRequestWithV2Chat as sendSearchRequest } from "./sendSearchRequest";
import { StreamEvent } from "@vectara/stream-query-client/lib/apiV2/types";
import { getFileProperties } from "../lib/wordHelpers";
import { CookieNames, getCookie } from "../lib/cookies";
export const SUMMARY_NUM_RESULTS = 7;

/* global */
// TODO figure out if we need to adjust this context value for stuff that we might need for the v2 chat and stream query apis.
interface SearchContextType {
  filterValue: string;
  setFilterValue: (source: string) => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  onSearch: ({
    value,
    filter,
    language,
    isPersistable,
  }: {
    value?: string;
    filter?: string;
    language?: SummaryLanguage;
    isPersistable?: boolean;
  }) => void;
  reset: () => void;
  isSearching: boolean;
  searchError: SearchError | undefined;
  searchResults: ApiV2.Query.SearchResult[] | undefined;
  searchTime: number;
  isSummarizing: boolean;
  summarizationError: SearchError | undefined;
  summarizationResponse: string | undefined;
  summarizationQuestion: string;
  summaryTime: number;
  language: SummaryLanguage;
  summaryNumResults: number;
  summaryNumSentences: number;
  summaryPromptName: string;
  history: HistoryItem[];
  clearHistory: () => void;
  searchResultsRef: React.MutableRefObject<HTMLElement[] | null[]>;
  selectedSearchResultPosition: number | undefined;
  selectSearchResultAt: (position: number) => void;
  onRetry: () => void;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

let searchCount = 0;

export const SearchContextProvider = ({ children }: Props) => {
  // const { search, rerank, hybrid } = useConfigContext();
  const { search } = useConfigContext();

  const [searchValue, setSearchValue] = useState<string>("");
  const [filterValue, setFilterValue] = useState("");
  const [conversationId, setConversationId] = useState<string | undefined>();

  // Language
  const [languageValue, setLanguageValue] = useState<SummaryLanguage>("eng");

  // History
  const [history, setHistory] = useState<HistoryItem[]>([]);

  // Basic search
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState<SearchError | undefined>();
  const [searchResponse, setSearchResponse] = useState<ChatResponse>();
  const [searchTime, setSearchTime] = useState<number>(0);

  // Summarization
  const [isSummarizing, setIsSummarizing] = useState(false);
  const [summarizationError, setSummarizationError] = useState<SearchError | undefined>();
  const [summarizationResponse, setSummarizationResponse] = useState<string>();
  const [summaryTime, setSummaryTime] = useState<number>(0);
  const [summarizationQuestion, setSummarizationQuestion] = useState<string>("");

  // Citation selection
  const searchResultsRef = useRef<HTMLElement[] | null[]>([]);
  const [selectedSearchResultPosition, setSelectedSearchResultPosition] = useState<number>();

  useEffect(() => {
    setHistory(retrieveHistory());
  }, []);

  const searchResults = searchResponse?.search_results;
  useEffect(() => {
    if (searchResults) {
      searchResultsRef.current = searchResultsRef.current.slice(0, searchResults.length);
    } else {
      searchResultsRef.current = [];
    }
  }, [searchResults]);

  const clearHistory = () => {
    setHistory([]);
    deleteHistory();
  };

  const selectSearchResultAt = (position: number) => {
    if (!searchResultsRef.current[position] || selectedSearchResultPosition === position) {
      // Reset selected position.
      setSelectedSearchResultPosition(undefined);
    } else {
      setSelectedSearchResultPosition(position);
      // Scroll to the selected search result.
      window.scrollTo({
        top: searchResultsRef.current[position]!.offsetTop - 78, // not sure what this magic number is for.
        behavior: "smooth",
      });
    }
  };

  const getLanguage = (): SummaryLanguage => (languageValue ?? "auto") as SummaryLanguage;

  const onRetry = () => {
    onSearch({ value: summarizationQuestion });
  };

  const onSearch = async ({
    value = searchValue,
    filter = filterValue,
    language = getLanguage(),
  }: {
    value?: string;
    filter?: string;
    language?: SummaryLanguage;
  }) => {
    if (!value?.trim()) return;

    const searchId = ++searchCount;
    //const augmentedFilter: string = "doc.id='" + window.documentTitle + "'";
    setSearchValue("");
    setFilterValue(filter);
    setSearchError(undefined);
    setSummarizationError(undefined);
    setLanguageValue(language);
    setSummarizationResponse(undefined);
    setSummarizationQuestion(value);

    // Save to history.
    setHistory(addHistoryItem({ query: value, filter, language }, history));

    // First call - only search results - should come back quicky while we wait for summarization (comment from V1 api, not sure if this is entirely applicable for current v2 api)
    setIsSearching(true);
    setIsSummarizing(true);
    setSelectedSearchResultPosition(undefined);
    let initialSearchResponse;
    // split https:// from the process.env.BACKEND_API_URL
    const domainWithoutHttps = process.env.BACKEND_API_URL?.split("https://")[1] || "localhost:443";

    try {
      const startTime = Date.now();
      initialSearchResponse = await sendSearchRequest({
        queryValue: value,
        corpusId: search.corpusId!,
        endpoint: domainWithoutHttps,
        apiKey: search.apiKey!,
        streamResponse: false,
      });
      const totalTime = Date.now() - startTime;

      // If we send multiple requests in rapid succession, we only want to
      // display the results of the most recent request.
      if (searchId === searchCount) {
        setIsSearching(false);
        setSearchTime(totalTime);
        setSearchResponse(initialSearchResponse);
        if (initialSearchResponse.search_results.length > 0) {
          setSearchError(undefined);
        } else {
          setSearchError({
            message: "There weren't any results for your search.",
          });
        }
      }
    } catch (error) {
      console.log("Search error", error);
      setIsSearching(false);
      setIsSummarizing(false);
      setSearchError(error as SearchError);
      setSearchResponse(undefined);
      return;
    }

    // Second call - search and summarize (if summary is enabled); this may take a while to return results
    if (initialSearchResponse.search_results.length > 0) {
      const startTime = Date.now();
      try {
        const domain = process.env.BACKEND_API_URL || "https://localhost:443";
        console.log("search domain: " + domain);

        // Ideally we can store these config values somewhere we can configure without hardcoding.
        const configurationOptions: ApiV2.StreamQueryConfig = {
          authToken: await getCookie(
            CookieNames.Token,
            async () => {
              // eslint-disable-next-line no-undef
              return await OfficeRuntime.auth.getAccessToken({
                allowSignInPrompt: false,
                allowConsentPrompt: false,
                forMSGraphAccess: false,
              });
            },
            1 / 24,
          ),
          domain: domain,
          customerId: search.customerId!,
          apiKey: search.apiKey!,
          corpusKey: search.corpusId!,
          query: value,
          search: {
            offset: 0,
            limit: 15,
            metadataFilter: "doc.id='" + (await getFileProperties()).title + "'",
          },
          generation: {
            maxUsedSearchResults: 15,
            responseLanguage: "eng",
            enableFactualConsistencyScore: false,

            promptName: "vectara-summary-ext-v1.2.0",
          },
        };
        const onStreamEventHandler = (event: StreamEvent) => {
          switch (event.type) {
            case "searchResults":
              break;
            case "chatInfo":
              setConversationId(event.chatId);
              console.log("chatId: " + event.chatId);
              console.log("conversationId: " + conversationId);
              break;

            case "generationChunk":
              // If we send multiple requests in rapid succession, we only want to
              // display the results of the most recent request.
              if (searchId === searchCount) {
                setSummarizationError(undefined);
                setSummarizationResponse(event.updatedText);
              }
              break;

            case "generationEnd":
              if (searchId === searchCount) {
                setIsSummarizing(false);
                setSummaryTime(Date.now() - startTime);
              }
              break;

            case "error":
            case "requestError":
            case "genericError":
            case "unexpectedEvent":
              setSummarizationError(undefined);
              setIsSummarizing(false);
              break;

            default:
              break;
          }
        };
        await streamQueryV2({
          streamQueryConfig: configurationOptions,
          onStreamEvent: onStreamEventHandler,
          includeRawEvents: false,
        });
      } catch (error) {
        setIsSummarizing(false);
        setSummarizationError(error as SearchError);
        setSummarizationResponse(undefined);
        return;
      }
    } else {
      setIsSummarizing(false);
      setSummarizationError({
        message: "No search results to summarize",
      });
      setSummarizationResponse(undefined);
    }
  };
  // If we take out the initial search results request this kinda works. Sometimes it bugs out because of the lack of search results as when the 200OK comes back from the /query reqeust we don't have a way to handle that in the event stream handler.
  // const onSearch = async ({
  //   value = searchValue,
  //   filter = filterValue,
  //   language = getLanguage(),
  // }: {
  //   value?: string;
  //   filter?: string;
  //   language?: SummaryLanguage;
  // }) => {
  //   if (!value?.trim()) return;

  //   const searchId = ++searchCount;
  //   setSearchValue("");
  //   setFilterValue(filter);
  //   setSearchError(undefined);
  //   setSummarizationError(undefined);
  //   setLanguageValue(language);
  //   setSummarizationResponse(undefined);
  //   setSummarizationQuestion(value);

  //   // Save to history.
  //   setHistory(addHistoryItem({ query: value, filter, language }, history));

  //   // First call - only search results - should come back quicky while we wait for summarization (comment from V1 api, not sure if this is entirely applicable for current v2 api)
  //   setIsSearching(true);
  //   setIsSummarizing(true);
  //   setSelectedSearchResultPosition(undefined);

  //   console.log("search.customerId: " + search.customerId);
  //   console.log("search.apiKey: " + search.apiKey);
  //   console.log("search.corpusId: " + search.corpusId);
  //   const startTime = Date.now();
  //   try {
  //     // Ideally we can store these config values somewhere we can configure without hardcoding.
  //     const configurationOptions: ApiV2.StreamQueryConfig = {
  //       domain: "https://localhost:443", // this proxies request to our backend hopefully.
  //       authToken: getCookie(CookieNames.Token), // This is our bootstrap token we have set from our frontend getAccessToken(). Not vectara auth token. We need this so that we can authorize the call to our backend api.
  //       customerId: search.customerId!,
  //       apiKey: search.apiKey!,
  //       corpusKey: search.corpusId!,
  //       query: value,
  //       search: {
  //         offset: 0,
  //         limit: 3,
  //         metadataFilter: "doc.id='" + (await getFileProperties()).title + "'",
  //       },
  //       generation: {
  //         maxUsedSearchResults: 1,
  //         responseLanguage: "eng",
  //         enableFactualConsistencyScore: false,

  //         promptName: "vectara-summary-ext-v1.2.0",
  //       },
  //     };
  //     console.log("configurationOptions: " + JSON.stringify(configurationOptions));
  //     const onStreamEventHandler = (event: StreamEvent) => {
  //       console.log("event: " + JSON.stringify(event));
  //       switch (event.type) {
  //         case "searchResults":
  //           if (event.searchResults.length > 0) {
  //             setIsSearching(false);
  //           } else {
  //             setIsSummarizing(false);
  //             setSummarizationError({
  //               message: "No search results to summarize",
  //             });
  //             setSummarizationResponse(undefined);
  //           }
  //           break;
  //         case "chatInfo":
  //           setConversationId(event.chatId);
  //           console.log("chatId: " + event.chatId);
  //           console.log("conversationId: " + conversationId);
  //           break;

  //         case "generationChunk":
  //           // If we send multiple requests in rapid succession, we only want to
  //           // display the results of the most recent request.
  //           if (searchId === searchCount) {
  //             setSummarizationError(undefined);
  //             setSummarizationResponse(event.updatedText);
  //           }
  //           break;

  //         case "generationEnd":
  //           if (searchId === searchCount) {
  //             setIsSummarizing(false);
  //             setSummaryTime(Date.now() - startTime);
  //           }
  //           break;

  //         case "error":
  //         case "requestError":
  //         case "genericError":
  //         case "unexpectedEvent":
  //           setSummarizationError(undefined);
  //           setIsSummarizing(false);
  //           break;

  //         default:
  //           break;
  //       }
  //     };
  //     await streamQueryV2({
  //       streamQueryConfig: configurationOptions,
  //       onStreamEvent: onStreamEventHandler,
  //       includeRawEvents: false,
  //     });
  //   } catch (error) {
  //     setIsSummarizing(false);
  //     setSummarizationError(error as SearchError);
  //     setSummarizationResponse(undefined);
  //     return;
  //   }
  // };
  const reset = () => {
    // Specifically don't reset language because that's more of a
    // user preference.
    onSearch({ value: "", filter: "" });
  };

  return (
    <SearchContext.Provider
      value={{
        filterValue,
        setFilterValue,
        searchValue,
        setSearchValue,
        onSearch,
        reset,
        isSearching,
        searchError,
        searchResults,
        searchTime,
        isSummarizing,
        summarizationError,
        summarizationResponse,
        summarizationQuestion,
        summaryTime,
        language: getLanguage(),
        summaryNumResults: 7,
        summaryNumSentences: 3,
        summaryPromptName: "vectara-summary-ext-v1.3.0",
        history,
        clearHistory,
        searchResultsRef,
        selectedSearchResultPosition,
        selectSearchResultAt,
        onRetry,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error("useSearchContext must be used within a SearchContextProvider");
  }
  return context;
};
