import React, { useState } from "react";
import { makeStyles, Button, Spinner } from "@fluentui/react-components";
import microsoftLogo from "../../../assets/icons8-microsoft-48.png";
import { useNavigate } from "react-router-dom";
// import { useConfigContext } from "../context/ConfigurationContext";
import { getFileProperties } from "../lib/wordHelpers";
import Logo from "../../../assets/lexiq.png";
import Header from "../components/Header";
import { userLogin } from "../helpers/sso-helper";
import { CookieNames, getCookie, setCookie } from "../lib/cookies";

const useStyles = makeStyles({
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center", // Center children vertically
    alignItems: "center", // Center children horizontally
    width: "100%", // Take full width of the container
    height: "50vh", // Cover half of the viewport height
    padding: "20px", // Apply 20px padding on all sides
    boxSizing: "border-box", // Ensure padding is included in the total width and height
  },
  button: {
    // Additional styles for the button itself if needed
  },
  loginButtonWrapper: {
    display: "flex",
    alignItems: "center", // Align items vertically centered
    gap: "10px", // Space between the icon and the button
  },
  microsoftIcon: {
    height: "20px", // Adjust the height as necessary
  },
  buttonIcon: {
    marginRight: "8px",
    height: "20px",
    verticalAlign: "middle",
  },
  loadingScreen: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    position: "fixed", // Cover the entire viewport
    backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent white background
    zIndex: 1000, // Ensure it's above other content
  },
  login: {
    minHeight: "100vh",
    paddingBottom: "20px",
  },
});

const Login: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  // const { search, setSearchConfig } = useConfigContext();
  const navigate = useNavigate();

  const handleUserLogin = async (result: any): Promise<void> => {
    try {
      if (result) {
        // Ideally we would set the authentication context here.
        setCookie(CookieNames.UserId, result.user_id, 1 / 24 / 60);
        console.log("Set user id to: " + getCookie(CookieNames.UserId, async () => "", 1 / 24 / 60) + ".");
        window.documentTitle = (await getFileProperties()).title;
      }
      setIsLoading(false);
      navigate("/chat");
    } catch (error) {
      console.error("Error handleUserLogin", error);
      setIsLoading(false);
    }
  };

  const attemptLogin = async () => {
    setIsLoading(true);
    try {
      await userLogin(handleUserLogin);
    } catch (error) {
      console.error("Error attempting login:", error);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className={classes.loadingScreen}>
        <Spinner label="Logging in..." />
      </div>
    );
  }

  return (
    <div className={classes.login}>
      <Header logo={Logo} title={"LexIQ"} message="Sign in to LexIQ" />
      <div className={classes.buttonContainer}>
        <div className={classes.loginButtonWrapper}>
          <Button onClick={attemptLogin}>
            <img src={microsoftLogo} alt="Microsoft" className={classes.microsoftIcon} />
            Sign in with Microsoft
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
