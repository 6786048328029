import { useState, useEffect } from "react";
// import { CookieNames, getCookie } from "../lib/cookies";
import { middlewareRequest } from "../middle-tier/middlewareHelpers";

interface FeatureAccessResponse {
  access: boolean;
}

export const useFeatureAccess = (featureId: string) => {
  console.log("Running useFeatureAccess with featureId: ", featureId);
  const [hasFeatureAccess, setHasFeatureAccess] = useState<boolean>(false);
  const [loadingFeatureAccess, setLoadingFeatureAccess] = useState(true);

  useEffect(() => {
    const fetchFeatureAccess = async () => {
      try {
        const body = { featureId: featureId };
        const data = await middlewareRequest<FeatureAccessResponse>("POST", `/access/features`, body);
        if (data && "access" in data) {
          setHasFeatureAccess(data.access);
        } else {
          console.error("Unexpected response format:", data);
          setHasFeatureAccess(false);
        }
      } catch (error) {
        console.error("Error fetching feature access:", error);
        setHasFeatureAccess(false);
      } finally {
        setLoadingFeatureAccess(false);
      }
    };

    fetchFeatureAccess();
  }, [featureId]);

  return { hasFeatureAccess, loadingFeatureAccess };
};
