import { CompoundButton, makeStyles, tokens } from "@fluentui/react-components";
import {
  CalendarDateRegular,
  DocumentBulletListFilled,
  DraftsFilled,
  FlagFilled,
  MultiselectLtrFilled,
} from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import TabLayout from "./TabLayout";

export const PROMPT_DETAILED = [
  {
    title: "Draft Clause",
    description: "Draft clauses for your contract.",
    icon: DraftsFilled,
    to: "/analyze/draft",
  },
  {
    title: "Risk & Red Flag Analysis",
    description: "Analyze the risk and red flags in your contract.",
    icon: FlagFilled,
    to: "/analyze/risk",
  },
  {
    title: "Summarize Selection",
    description: "This is a summary of the highlighted text.",
    icon: MultiselectLtrFilled,
    to: "/analyze/summarize-selection",
  },
  {
    title: "Summarize Contract",
    description: "Get a summary of your contract.",
    icon: DocumentBulletListFilled,
    to: "/analyze/summarize-contract",
  },
  {
    title: "Summarize Dates",
    description: "Summarize the dates in your contract.",
    icon: CalendarDateRegular,
    to: "/analyze/summarize-dates",
  },
];

const useStyles = makeStyles({
  insights: {
    display: "flex",
    flexDirection: "column",
    rowGap: tokens.spacingVerticalL,
  },
  insightsButton: {
    width: "100%",
    justifyContent: "flex-start",
  },
});

const DocumentTools = () => {
  const styles = useStyles();

  const navigate = useNavigate();

  return (
    <TabLayout>
      <div className={styles.insights}>
        {PROMPT_DETAILED.map((prompt, index) => (
          <CompoundButton
            className={styles.insightsButton}
            onClick={() => navigate(prompt.to)}
            key={index}
            icon={<prompt.icon />}
            secondaryContent={prompt.description}
          >
            {prompt.title}
          </CompoundButton>
        ))}
      </div>
    </TabLayout>
  );
};

export default DocumentTools;
