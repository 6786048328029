import {
  Body1,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Field,
  Spinner,
  Textarea,
  Tooltip,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { CopySelectRegular, DeleteRegular, DocumentEditRegular, TextAddRegular } from "@fluentui/react-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import TabLayout from "../components/TabLayout";
import { onAskClick } from "../lib/draft";
import { insertTextAtCursor } from "../lib/wordHelpers";
import { useFeatureAccess } from "../hooks/useFeatureAccess";
import { FeatureAccessBanner } from "../components/FeatureAccessBanner";

import { useRecoilState } from "recoil";
import { FEATURE_IDS } from "../config/features";
import { DraftInterface, draftState } from "../state/draftState";
/* global */

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flex: "1 100%",
    flexDirection: "column",
  },
  drafts: {
    display: "flex",
    flexDirection: "column",
    flex: "1 100%",
    paddingBottom: "15px",
  },
  messageWrapper: {
    marginBottom: "15px",
  },
  message: {
    backgroundColor: tokens.colorNeutralBackground1Hover,
    position: "relative",
    ":after": {
      content: "''",
      display: "block",
      height: "100px",
      width: "100%",
      // background: `linear-gradient(180deg,transparent 0%, transparent 50%, ${tokens.colorNeutralBackground1Hover} 95%)`,
      background: `linear-gradient(to bottom,transparent 0%, ${tokens.colorNeutralBackground1Hover} 55%)`,
      position: "absolute",
      bottom: "0",
      left: "0",
      zIndex: "2",
      borderTopStyle: "none",
      borderLeftStyle: "none",
      borderRightStyle: "none",
      borderBottomStyle: "none",
      top: "initial !important",
    },
  },
  messageActive: {
    height: "100%",
    flex: "1 100%",
    backgroundColor: tokens.colorNeutralBackground1Hover,
    flexDirection: "column",
  },
  contentWrapper: {
    cursor: "pointer",
    flex: "1 100%",
  },
  messageHeading: {
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightSemibold,
    margin: 0,
    display: "flex",
    alignItems: "center",
    marginBottom: tokens.spacingVerticalS,
  },
  messageHeadingActive: {
    display: "none",
  },
  messageHeadingIcon: {
    marginRight: tokens.spacingHorizontalSNudge,
  },
  messageInput: {
    margin: 0,
    fontWeight: tokens.fontWeightBold,
    marginBottom: tokens.spacingVerticalS,
  },
  messageInputActive: { display: "none" },
  messageOutput: {
    margin: 0,
    maxHeight: "100px",
    overflow: "hidden",
    position: "relative",
    zIndex: "1",
  },
  messageOutputActive: {
    maxHeight: "100%",
  },
  footer: {
    position: "relative",

    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: "3",
  },
  footerActive: {
    position: "relative",
    width: "100%",
  },
  selectedHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  generateButton: {
    marginTop: tokens.spacingHorizontalS,
    width: "100%",
  },
  spinnerWrapper: {
    flex: "1 100%",
    display: "flex",
    // height: "40px",
    justifyContent: "center",
    alignItems: "center",
  },
});

const draftSchema = z.object({
  input: z.string().min(1, { message: "Required" }),
});

type DraftFormValue = z.infer<typeof draftSchema>;

const Draft: React.FC = () => {
  const [drafts, setDrafts] = useRecoilState(draftState);
  const [loading, setLoading] = useState(false);
  const [activeDraft, setActiveDraft] = useState<DraftInterface | null>(null);

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
    setValue,
  } = useForm<DraftFormValue>({
    resolver: zodResolver(draftSchema),
  });

  const styles = useStyles();
  const { hasFeatureAccess, loadingFeatureAccess } = useFeatureAccess(FEATURE_IDS.RISK_ANALYSIS);

  const onSubmit = async (data: z.infer<typeof draftSchema>) => {
    console.log("Submit ", data);
    setLoading(true);
    const response = await onAskClick(data.input);
    console.log("response", response);
    setDrafts;

    setDrafts((drafts) => [
      ...drafts,
      {
        id: drafts.length + 1,
        input: data.input,
        output: response.join("\n"),
      },
    ]);

    setLoading(false);
  };

  const copyTextToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
  };

  const insertText = async (text: string) => {
    insertTextAtCursor(text);
  };

  const deleteDraft = (id: number) => {
    setDrafts((drafts) => drafts.filter((draft) => draft.id !== id));
  };

  useEffect(() => {
    setValue("input", activeDraft?.input ?? "");
  }, [activeDraft, setValue]);

  return (
    <TabLayout>
      <div className={styles.wrapper}>
        {!loadingFeatureAccess && !hasFeatureAccess && (
          <FeatureAccessBanner featureName="Draft Generation" subscriptionLevel="Basic" />
        )}
        <div className={styles.messageWrapper}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Field validationState={errors.input?.message ? "error" : "none"} validationMessage={errors.input?.message}>
              <Textarea
                size="large"
                placeholder="Describe the clause. Be specific about provisions and other details."
                {...register("input")}
                disabled={!hasFeatureAccess && !loadingFeatureAccess}
              />
            </Field>
            <Button
              onClick={handleSubmit(onSubmit)}
              className={styles.generateButton}
              disabled={!hasFeatureAccess && !loadingFeatureAccess}
            >
              Generate
            </Button>
          </form>
        </div>

        {loading && (
          <div className={styles.spinnerWrapper}>
            <Spinner />
          </div>
        )}

        <div className={styles.drafts}>
          {drafts.map((draft) => (
            <Card className={activeDraft ? styles.messageActive : styles.message} key={draft.id}>
              <CardHeader
                className={styles.selectedHeader}
                header={
                  activeDraft ? (
                    <Body1>
                      <b>Draft Content</b>
                    </Body1>
                  ) : undefined
                }
                action={
                  activeDraft ? (
                    <Button onClick={() => setActiveDraft(null)} aria-label="Close">
                      Close
                    </Button>
                  ) : undefined
                }
              />
              <div onClick={() => setActiveDraft(draft)} className={styles.contentWrapper}>
                <h3 className={activeDraft ? styles.messageHeadingActive : styles.messageHeading}>
                  <DocumentEditRegular className={styles.messageHeadingIcon} />
                  Draft
                </h3>
                <p className={activeDraft ? styles.messageInputActive : styles.messageInput}>{draft.input}</p>
                <p className={activeDraft ? styles.messageOutputActive : styles.messageOutput}>{draft.output}</p>
              </div>
              <CardFooter
                className={styles.footer}
                // action={<Button appearance="transparent" icon={<MoreHorizontal20Regular />} />}
              >
                <div>
                  <Tooltip withArrow content="Delete" relationship="label">
                    <Button
                      onClick={() => deleteDraft(draft.id)}
                      icon={<DeleteRegular />}
                      disabled={!hasFeatureAccess && !loadingFeatureAccess}
                    />
                  </Tooltip>
                </div>
                <div>
                  <Tooltip withArrow content="Copy to Clipboard" relationship="label">
                    <Button
                      onClick={() => copyTextToClipboard(draft?.output)}
                      icon={<CopySelectRegular />}
                      disabled={!hasFeatureAccess && !loadingFeatureAccess}
                    />
                  </Tooltip>
                  <Button
                    onClick={() => insertText(draft?.output ?? "")}
                    icon={<TextAddRegular />}
                    disabled={!hasFeatureAccess && !loadingFeatureAccess}
                  >
                    Insert at Cursor
                  </Button>
                </div>
              </CardFooter>
            </Card>
          ))}
        </div>
      </div>
    </TabLayout>
  );
};

export default Draft;
