import { Stripe as StripeType } from "@stripe/stripe-js";
import { RouteObject, RouterProvider, createBrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { ChatContextProvider } from "../context/ChatContext";
import { CheckoutProvider } from "../context/CheckoutContext";
import { ConfigContextProvider } from "../context/ConfigurationContext";
import { SearchContextProvider } from "../context/SearchContext";
import Chat from "../routes/Chat";
import LeaseAbstractor from "../routes/LeaseAbstractor";
import Login from "../routes/Login";
import NVCAAssistant from "../routes/NVCAAssistant";
import Risk from "../routes/Risk";
import Stripe from "../routes/Stripe";
import SummarizeDates from "../routes/SummarizeDates";
import SummarizeDocument from "../routes/SummarizeDocument";
import SummarizeSelection from "../routes/SummarizeSelection";
import Draft from "../routes/draft";
import Root from "../routes/root";
import AiAgents from "./AiAgents";
import CheckoutForm from "./CheckoutForm";
import DocumentTools from "./DocumentTools";
import Return from "./Return";

export interface AppProps {
  isOfficeInitialized: boolean;
  stripePromise: Promise<StripeType | null>;
}

export const PAGE_PATHS: RouteObject[] = [
  {
    path: "/",
    element: <Login />,
  },

  {
    path: "/root",
    element: <Root title="LexIQ App" />,
  },
  {
    path: "/analyze",
    element: <DocumentTools />,
  },
  {
    path: "/analyze/draft",
    element: <Draft />,
  },
  {
    path: "/analyze/risk",
    element: <Risk />,
  },
  {
    path: "/analyze/summarize-selection",
    element: <SummarizeSelection />,
  },
  {
    path: "/analyze/summarize-contract",
    element: <SummarizeDocument />,
  },
  {
    path: "/analyze/summarize-dates",
    element: <SummarizeDates />,
  },
  {
    path: "/chat",
    element: (
      <ChatContextProvider>
        <Chat />
      </ChatContextProvider>
    ),
  },
  {
    path: "/stripe",
    element: <Stripe />,
  },
  {
    path: "/checkout",
    element: <CheckoutForm />,
  },
  {
    path: "/return",
    element: <Return />,
  },
  {
    path: "/ai-agents",
    element: <AiAgents />,
  },
  {
    path: "/ai-agents/lease-abstractor",
    element: <LeaseAbstractor />,
  },
  {
    path: "/ai-agents/nvca-assistant",
    element: <NVCAAssistant />,
  },
];

const router = createBrowserRouter(PAGE_PATHS, {
  basename: "/taskpane.html",
});

const App = (props: AppProps) => {
  const { isOfficeInitialized } = props;
  // App has to wait until the office js is initialized which passed down through props.
  if (!isOfficeInitialized) {
    return "Loading...";
  }

  console.log("App is initialized");

  return (
    <RecoilRoot>
      <CheckoutProvider stripePromise={props.stripePromise}>
        <ConfigContextProvider>
          <SearchContextProvider>
            <RouterProvider router={router} />
          </SearchContextProvider>
        </ConfigContextProvider>
      </CheckoutProvider>
    </RecoilRoot>
  );
};

export default App;
