import { openAiQuery } from "./openai";
import { conductAnalyzeV2, VectaraQueryApiRequest } from "./vectaraHelpers";
import { getFileProperties } from "./wordHelpers";

/* global */

export async function conductContractSummary(documentId: string, upload = false) {
  const queryText = `Summarize this legal contract`;

  const promptText = `
  [
    {
        "role": "system",
        "content": "You are a legal expert that understands contractual agreement documents. You are being asked the following question about a contract document between two parties. Base the answer only on the following information."
    },
    #foreach ($qResult in $vectaraQueryResults)
    #if ($foreach.first)
        {"role": "user", "content": "Search for '\${vectaraQuery}', and give me the first search result."},
        {"role": "assistant", "content": "\${qResult.getText()}" },
    #else
        {"role": "user", "content": "Give me the $vectaraIdxWord[$foreach.index] search result."},
        {"role": "assistant", "content": "$qResult.docMetadata().get('answerDate') \${qResult.getText()}" },
    #end
    #end
    {
        "role": "user",
        "content": "Generate a comprehensive and informative answer for the question '$esc.java(\${vectaraQuery})' solely based on the search results in this chat. You must only use information from the provided results. 
  Please always refer to the parties by their full respective names. Structure the output something like this:
  1. Line Heading: Some information about the line heading
  2. Line Heading: Some information about the line heading
    a. Some additional information about the line heading
    b. Even more information about the line heading
  3. Line Heading: Some information about the line heading

Please always refer to the parties by their full respective names. Structure the output in a neat numbered HTML list like this:
Give a brief summary of the document and then list out the important information in the document.
<ol>
    <li><strong>Contact Term</strong>: From the date of execution of this Agreement, the term of this Agreement shall be for a period of 10 years.
        <ol>
            <li><strong>Termination</strong>: This Agreement may be terminated by either party giving 30 days written notice to the other party.</li>
            <li><strong>Termination</strong>: This Agreement may be terminated by either party giving 30 days written notice to the other party.</li>
        </ol>
      </li>
</ol>      
  "
    }
  ]`;
  try {
    const docTitle = (await getFileProperties()).title;
    let metadataFilterVal: string;
    if (documentId) {
      metadataFilterVal = "doc.id = " + `'${documentId}'`;
    } else {
      metadataFilterVal = "doc.id = " + `'${docTitle}'`;
    }
    console.log("metadataFilterVal", metadataFilterVal);
    const request: VectaraQueryApiRequest = {
      query: queryText,
      numResults: 20,
      search: {
        corpora: [
          {
            // Corpus key is injected server side.
            corpus_key: "",
            metadata_filter: metadataFilterVal,
            lexical_interpolation: 0.1,
            semantics: "default",
          },
        ],
        context_configuration: {
          sentences_after: 2,
          sentences_before: 2,
          start_tag: "<b>",
          end_tag: "</b>",
        },
      },
      generation: {
        // Seems like we rarely use more than 10 search results even if we have chunks of 1000 chars.
        max_used_search_results: 20,
        // prompt_name: "mockingbird-1.0-2024-07-16",
        prompt_name: "vectara-summary-ext-24-05-large",
        prompt_text: promptText,
        enable_factual_consistency_score: false,
        response_language: "eng",
        model_parameters: {
          max_tokens: 2048,
          temperature: 0.1,
          frequency_penalty: 0.5,
          presence_penalty: 0,
        },
      },
    };
    let response = await conductAnalyzeV2(documentId, upload, request);
    return response.summaryResult;
  } catch (error) {
    console.error("Error summarizing document", error);
    throw error;
  }
}

export async function summarizeSelectionExecutiveSummary(documentText: string): Promise<string> {
  console.log("Summarizing Selection");

  // Prepare your summary prompt in a chat message format
  const messages = "Provide a one paragraph summary of the following text selection:\n\n" + documentText;
  return await openAiQuery(messages);
}

export async function summarizeDates(documentId: string, upload = false) {
  console.log("Summarizing Dates");

  const queryText =
    "List the key dates and deadlines within this document in bullet point format and do not show the document ID and do not cite other documents in the corpus. Do not mention the document ID in the response.";
  // We have to make sure that we use this kind of prompt text with the right new lines or else it will not be added as the right rendered prompt and it will default to the wrong prompt.
  // const string_prompt =
  //   '[\n  {"role": "system", "content": "You are a helpful search assistant."},\n  #foreach ($qResult in $vectaraQueryResults)\n     {"role": "user", "content": "Give me the $vectaraIdxWord[$foreach.index] search result."},\n     {"role": "assistant", "content": "${qResult.getText()}" },\n  #end\n  {"role": "user", "content": "Generate a summary for the query \'${vectaraQuery}\' based on the above results."}\n]';
  // We are using the default prompt vectara provides for now given we aren't putting our own prompt text in
  const docTitle = (await getFileProperties()).title;
  let metadataFilterVal: string;
  if (documentId) {
    metadataFilterVal = "doc.id = " + `'${documentId}'`;
  } else {
    metadataFilterVal = "doc.id = " + `'${docTitle}'`;
  }
  console.log("metadataFilterVal", metadataFilterVal);
  const request: VectaraQueryApiRequest = {
    query: queryText,
    numResults: 20,
    search: {
      corpora: [
        {
          // Corpus key is injected server side.
          corpus_key: "",
          metadata_filter: metadataFilterVal,
          lexical_interpolation: 0,
          semantics: "default",
        },
      ],
      context_configuration: {
        sentences_after: 2,
        sentences_before: 2,
        start_tag: "<b>",
        end_tag: "</b>",
      },
    },
    generation: {
      // Seems like we rarely use more than 10 search results even if we have chunks of 1000 chars.
      max_used_search_results: 20,
      // prompt_name: "mockingbird-1.0-2024-07-16",
      // Omni is better for summarizing without getting too many too much text to summarize issues with other prompt name models.
      prompt_name: "vectara-summary-ext-24-05-med-omni",
      enable_factual_consistency_score: false,
      response_language: "eng",
      model_parameters: {
        max_tokens: 2048,
        temperature: 0.1,
        frequency_penalty: 0,
        presence_penalty: 0,
      },
    },
  };
  let response = await conductAnalyzeV2(documentId, upload, request);
  response.summaryResult = response.summaryResult.replace(/ ?\[\d+(,\s*\d+)*\]?/g, ""); // Remove references and maintain new lines

  return response;
}
