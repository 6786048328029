/* global Office, Word */

let documentRead: string;

export async function getTextFromDocument(): Promise<string> {
  try {
    return Word.run(async (context) => {
      const body = context.document.body;
      context.load(body, "text");
      await context.sync();
      return body.text;
    });
  } catch (error) {
    console.error("Error retrieving text from document: " + error);
    throw error; // Rethrow the error to be handled by the caller
  }
}

export function convertStringToBytes(text: string): Uint8Array {
  const encoder = new TextEncoder();
  return encoder.encode(text);
}

export async function getFileContents(): Promise<string> {
  return new Promise((resolve, reject) => {
    const myTemplate = document.getElementById("template-file") as HTMLInputElement;
    if (!myTemplate.files || myTemplate.files.length === 0) {
      reject(new Error("No file selected"));
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target && typeof event.target.result === "string") {
        // Remove the metadata before the Base64-encoded string.
        const startIndex = event.target.result.indexOf("base64,");
        // const documentMetadata = event.target.result.substring(0, startIndex + 7);
        documentRead = event.target.result.substring(startIndex + 7);
        console.log("documentRead: " + documentRead);
        resolve(documentRead);
      } else {
        reject(new Error("Failed to read file"));
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
    openInsertedFile(documentRead);
    reader.readAsDataURL(myTemplate.files[0]);
    console.log("documentRead: " + documentRead);
  });
}

export function generateUniqueId(): string {
  return "id-" + Math.random().toString(36).substring(2, 15) + Math.floor(Date.now() / 1000).toString(36);
}

export async function openInsertedFile(documentRead: string): Promise<void> {
  try {
    console.log("Test 1");
    await Word.run(async (context) => {
      // Use the Base64-encoded string representation of the selected .docx file.
      context.document.insertFileFromBase64(documentRead, "Replace", {
        importTheme: true,
        importStyles: true,
        importParagraphSpacing: true,
        importPageColor: true,
        importDifferentOddEvenPages: true,
      });
      await context.sync();
    });
  } catch (error) {
    console.error("Error in inserting open file:" + error);
    throw error; // Rethrow the error to be handled by the caller
  }
}

export async function setDocumentTitle(newTitle: string): Promise<void> {
  Word.run(async function (context) {
    // Get the currently selected text range
    const properties = context.document.properties;
    context.load(properties);
    await context.sync();

    properties.title = newTitle;
    await context.sync();
    console.log("File properties updated: " + JSON.stringify([properties]));
    return;
  });
}

export async function getFileProperties(): Promise<Word.DocumentProperties> {
  return Word.run(async function (context) {
    // Get the currently selected text range
    const doc = context.document;
    await doc.properties.load("*");
    await context.sync();
    return doc.properties;
  });
}
export async function getDocumentProperties(): Promise<Office.FileProperties> {
  return new Promise((resolve, reject) => {
    Office.context.document.getFilePropertiesAsync((asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        resolve(asyncResult.value);
      } else {
        reject(asyncResult.error);
      }
    });
  });
}
// Function to get the filename from the document properties
export async function getDocumentFilename(): Promise<string> {
  return new Promise((resolve, reject) => {
    Office.context.document.getFilePropertiesAsync((asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        const url: string = asyncResult.value.url;
        const filename: string = url.substring(url.lastIndexOf("/") + 1);
        resolve(filename);
      } else {
        reject(asyncResult.error);
      }
    });
  });
}

export function getSelectedText(): Promise<string> {
  return Word.run(function (context) {
    // Get the currently selected text range
    const range = context.document.getSelection();
    // Load the text of the selected range
    context.load(range, "text");

    return context.sync().then(function () {
      // Return the text of the selected range
      return range.text;
    });
  });
}

export function clearSelectedText(): void {
  Word.run(function (context) {
    // Get the currently selected text range
    const range = context.document.getSelection();
    // Clear the selected range
    range.select("Start");
    return context.sync();
  });
}

export function insertTextAtCursor(text: string): void {
  Word.run(function (context) {
    // Get the current selection and then insert text.
    const range = context.document.getSelection();
    range.insertText(text, "End");
    return context.sync();
  });
}
