import { Accordion, AccordionItem, AccordionPanel, makeStyles, tokens } from "@fluentui/react-components";
import { ChatReference } from "./ChatReference";
import { ApiV2 } from "@vectara/stream-query-client";

/* global */

type Props = {
  searchResults: ApiV2.Query.SearchResult[];
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
};

const useStyles = makeStyles({
  header: {
    borderRadius: 0,
    borderBottom: `1px solid ${tokens.colorNeutralStroke1}`,
  },
  list: {
    padding: 0,
    paddingInlineStart: tokens.spacingHorizontalM,
    rowGap: tokens.spacingVerticalM,
    display: "flex",
    flexDirection: "column",
  },
});

export const ChatReferences = ({ searchResults }: Props) => {
  const styles = useStyles();
  return (
    <Accordion collapsible>
      <AccordionItem value="1">
        {/* <AccordionHeader className={styles.header}>
          Based on {searchResults.length} {searchResults.length === 1 ? "fact" : "facts"}
        </AccordionHeader> */}
        <AccordionPanel>
          <ol className={styles.list}>
            {searchResults.map((result, i) => (
              <ChatReference key={i} result={result} position={i} />
            ))}
          </ol>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
