import React from "react";
import CustomPricingTable from "../components/CustomPricingPage";
import { useLocation } from "react-router-dom";

const Stripe: React.FC = () => {
  const location = useLocation();
  const { history } = (location.state as { history?: string[] }) || {};

  return <CustomPricingTable history={history || []} />;
};

export default Stripe;
