import {
  Body1,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Spinner,
  Text,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { ArrowSyncCircleRegular, DeleteRegular } from "@fluentui/react-icons";
import React, { useEffect, useState } from "react";
import { Message } from "../components/Message";
import { summarizeSelectionExecutiveSummary } from "../lib/summarize";
import { clearSelectedText, getSelectedText } from "../lib/wordHelpers";
import TabLayout from "../components/TabLayout";
import { useFeatureAccess } from "../hooks/useFeatureAccess";
import { FeatureAccessBanner } from "../components/FeatureAccessBanner";
import { FEATURE_IDS } from "../config/features";

/* global */

const useStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralBackground1Hover,
  },
  wrapper: {
    display: "flex",
    flex: "1 100%",
    flexDirection: "column",
    rowGap: tokens.spacingVerticalM,
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: tokens.spacingHorizontalSNudge,
  },
  loader: {
    // marginTop: tokens.spacingVerticalXXXL,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 100%",
  },
  clear: {
    // marginBottom: tokens.spacingVerticalM,
  },
  selectedText: {
    backgroundColor: tokens.colorNeutralBackground1Hover,
    position: "relative",
    maxHeight: "150px",
    ":after": {
      content: "''",
      display: "block",
      height: "50px",
      width: "100%",
      // background: `linear-gradient(180deg,transparent 0%, transparent 50%, ${tokens.colorNeutralBackground1Hover} 95%)`,
      background: `linear-gradient(to bottom,transparent 0%, ${tokens.colorNeutralBackground1Hover} 75%)`,
      position: "absolute",
      bottom: "0",
      left: "0",
      zIndex: "2",
      borderTopStyle: "none",
      borderLeftStyle: "none",
      borderRightStyle: "none",
      borderBottomStyle: "none",
      top: "initial !important",
    },
  },
  selectedHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

const SummarizeSelection: React.FC = () => {
  const styles = useStyles();
  const { hasFeatureAccess, loadingFeatureAccess } = useFeatureAccess(FEATURE_IDS.SUMMARIZE_SELECTION);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState<string | null>(null);
  const [selectedText, setSelectedText] = useState<string | null>(null);

  const getSelection = async () => {
    const text = await getSelectedText();
    if (text.length > 0) {
      setSelectedText(text);
    } else {
      setSelectedText(null);
    }
  };

  const clearSelection = async () => {
    await clearSelectedText();
    setSummary(null);
    setSelectedText(null);
  };

  const executiveSummary = async () => {
    setLoading(true);
    try {
      if (!selectedText) {
        return;
      }
      const summary = await summarizeSelectionExecutiveSummary(selectedText);
      setSummary(summary);
      console.log("summary", summary);
    } catch (error) {
      console.error("Error summarizing selection:", error);
    }
    setLoading(false);
  };

  const clearSummary = () => {
    setSummary(null);
  };

  useEffect(() => {
    const getText = setInterval(async () => {
      await getSelection();
    }, 1000);

    return () => clearInterval(getText);
  }, [selectedText]);

  // if (loadingFeatureAccess) {
  //   return <Spinner />;
  // }

  return (
    <TabLayout>
      <div className={styles.wrapper}>
        {!hasFeatureAccess && !loadingFeatureAccess && (
          <FeatureAccessBanner featureName="Selection Summarization" subscriptionLevel="Basic" />
        )}

        {!selectedText && <Message title="Getting started" text="Simply select some text in your document." />}
        {selectedText && (
          <>
            <Card className={styles.selectedText}>
              <CardHeader
                className={styles.selectedHeader}
                header={
                  <Body1>
                    <b>Selection</b>
                  </Body1>
                }
                action={
                  <Button onClick={clearSelection} aria-label="More options">
                    Clear
                  </Button>
                }
              />
              <Text>{selectedText}</Text>
            </Card>
          </>
        )}

        {loading && <Spinner />}

        {selectedText && !loading && !summary && (
          <>
            <Button onClick={executiveSummary} disabled={!hasFeatureAccess}>
              Summary
            </Button>
          </>
        )}

        {summary && !loading ? (
          <Card className={styles.root}>
            <CardHeader
              className={styles.selectedHeader}
              header={
                <Body1>
                  <b>Summary</b>
                </Body1>
              }
            />
            <Text>{summary}</Text>
            {/* {error}
          {references.map((reference) => (
            <>
              <Text>{reference.text}</Text>
              <Text>{reference.value}</Text>
            </>
          ))} */}
            <CardFooter className={styles.footer}>
              <Button onClick={clearSummary} icon={<DeleteRegular />} />
              <Button
                onClick={executiveSummary}
                icon={<ArrowSyncCircleRegular />}
                disabled={!hasFeatureAccess && !loadingFeatureAccess}
              >
                Regenerate Summary
              </Button>
            </CardFooter>
          </Card>
        ) : null}
      </div>
    </TabLayout>
  );
};

export default SummarizeSelection;
