import { atom } from "recoil";
import { generateUniqueId } from "../lib/wordHelpers";

export interface DocumentState {
  documentId: string | null;
  lastUploaded: Date | null;
  isSyncing: boolean;
}

export const documentState = atom<DocumentState>({
  key: "documentState", // unique ID (with respect to other atoms/selectors)
  default: {
    documentId: "TEMP_LEXIQ-" + generateUniqueId(),
    lastUploaded: null,
    isSyncing: false,
  },
});
