import { useState, useEffect } from "react";
import { useFeatureAccess } from "./useFeatureAccess";

export const useInitialAnalysis = (featureId: string) => {
  const { hasFeatureAccess, loadingFeatureAccess } = useFeatureAccess(featureId);
  const [shouldAnalyze, setShouldAnalyze] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  useEffect(() => {
    if (!loadingFeatureAccess && hasFeatureAccess && !initialLoadComplete) {
      setShouldAnalyze(true);
    }
  }, [loadingFeatureAccess, hasFeatureAccess, initialLoadComplete]);

  const completeInitialLoad = () => {
    setInitialLoadComplete(true);
    setShouldAnalyze(false);
  };

  return {
    shouldAnalyze,
    hasFeatureAccess,
    loadingFeatureAccess,
    initialLoadComplete,
    completeInitialLoad,
  };
};
