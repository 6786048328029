import { IStackTokens, Stack } from "@fluentui/react";
import {
  Button,
  Caption1,
  Card,
  CardFooter,
  CardHeader,
  Spinner,
  Text,
  Tooltip,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import {
  ArrowSyncCircleRegular,
  Edit12Regular,
  Eye12Regular,
  InfoRegular,
  WarningRegular,
} from "@fluentui/react-icons";
import mammoth from "mammoth";
import React, { useState } from "react";
import TabLayout from "../components/TabLayout";
import { conductFillinNVCATemplate, Filler } from "../lib/nvcaAssistant";

/* global Word */

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    rowGap: tokens.spacingVerticalM,
    flex: "1 100%",
  },
  label: {
    display: "block",
    marginBottom: tokens.spacingVerticalS,
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 100%",
  },
  button: {
    flexGrow: "1",
  },
});

const blanks: Filler[] = [
  {
    Category: "Corporation Name",
    Subject: "corporation_name",
    Location: "[_________]",
    Value: "",
  },
  {
    Category: "Corporation Name",
    Subject: "corporation_name",
    Location:
      "[____________], a corporation organized and existing under and by virtue of the provisions of the General Corporation Law",
    Value: "",
  },
  {
    Category: "Corporation Name",
    Subject: "corporation_name",
    Location: "That the name of this corporation is [_______________]",
    Value: "",
  },
  {
    Category: "Incorporated Date",
    Subject: "incorporated_date",
    Location: "corporation was originally incorporated pursuant to the General Corporation Law on [________ __, 20__]",
    Value: "",
  },
  {
    Category: "Corporation Name",
    Subject: "corporation_name",
    Location: "under the name [_______________]",
    Value: "",
  },
  {
    Category: "Corporation Name",
    Subject: "corporation_name",
    Location: "The name of this corporation is [_______________]",
    Value: "",
  },
  {
    Category: "Address of Corporation",
    Subject: "corporation_full_address",
    Location: "The address of the registered office of the Corporation in the State of Delaware is [_____________]",
    Value: "",
  },
  {
    Category: "Address of Corporation",
    Subject: "corporation_city",
    Location: "in the City of [__________],",
    Value: "",
  },
  {
    Category: "Address of Corporation",
    Subject: "corporation_county",
    Location: "County of [__________].",
    Value: "",
  },
  {
    Category: "Corporation Name",
    Subject: "corporation_name",
    Location: "The name of its registered agent at such address is [_____________________].",
    Value: "",
  },
  {
    Category: "Authorized Stock",
    Subject: "total_number_of_authorized_shares",
    Location: "stock which the Corporation shall have the authority to issue is [______]",
    Value: "",
  },
  {
    Category: "Authorized Stock",
    Subject: "common_stock_authorization",
    Location: "There are [_____] shares of authorized Common Stock",
    Value: "",
  },
  {
    Category: "Authorized Stock",
    Subject: "common_stock_par_value",
    Location: "$[_____] par value per share (“Common Stock”)",
    Value: "",
  },
  {
    Category: "Authorized Stock",
    Subject: "preferred_stock_authorization",
    Location: "and [______] shares of authorized Preferred Stock",
    Value: "",
  },
  {
    Category: "Authorized Stock",
    Subject: "preferred_stock_par_value",
    Location: "$[______] par value per share (“Preferred Stock”)",
    Value: "",
  },
  {
    Category: "Accruing Dividends",
    Subject: "accrue_preferred_stock_dividends",
    Location: "dividends at the rate per annum of $[___] per share",
    Value: "",
  },
  {
    Category: "Preferred Stock - Participating",
    Subject: "liquidation_preferred_stock_multiplier",
    Location:
      "an amount per share of each such series of Preferred Stock equal to the greater of (i) [__ times] the applicable Original Issue Price",
    Value: "",
  },
  {
    Category: "Common Stock - Participating",
    Subject: "liquidation_common_stock_multiplier",
    Location:
      "any payment shall be made to the holders of Common Stock by reason of their ownership thereof, an amount per share equal to [___ times] the applicable Original Issue Price",
    Value: "",
  },
  {
    Category: "Common Stock - Participating",
    Subject: "liquidation_common_stock_multiplier",
    Location:
      "any payment shall be made to the holders of Common Stock by reason of their ownership thereof, an amount per share equal to [___ times] the applicable Original Issue Price",
    Value: "",
  },
  {
    Category: "Deemed Liquidation Event",
    Subject: "assets_in_disposition_of_deemed_liquidation",
    Location:
      "Corporation of all or substantially all the assets of the Corporation and its subsidiaries taken as a whole (including, without limitation, [_____________])",
    Value: "",
  },
  {
    Category: "Election of Directors",
    Subject: "outstanding_shares_of_preferred_stock",
    Location:
      "At all times when at least [__] shares of Preferred Stock remain outstanding (subject to appropriate adjustment in the event of any stock dividend",
    Value: "",
  },
  {
    Category: "Election of Directors",
    Subject: "number_of_preferred_director_in_election",
    Location:
      "exclusively and voting together as a separate class on an as-converted to Common Stock basis, shall be entitled to elect [__] director",
    Value: "",
  },
  {
    Category: "Election of Directors",
    Subject: "number_of_at_large_director_in_election",
    Location:
      "the holders of record of the shares of Common Stock, exclusively and voting together as a separate class, shall be entitled to elect [__] directors of the Corporation",
    Value: "",
  },
  {
    Category: "Protective Provisions",
    Subject: "preferred_stock_protection_threshold",
    Location: "At any time when at least [____] shares of Preferred Stock",
    Value: "",
  },
  {
    Category: "Indebtedness Limitation",
    Subject: "maximum_indebtedness_threshold",
    Location:
      "unless the aggregate indebtedness of the Corporation and its subsidiaries for borrowed money following such action would not exceed $_____",
    Value: "",
  },
  {
    Category: "Conversion Price",
    Subject: "series_a_preferred_stock_conversion_price",
    Location: "applicable to the Preferred Stock as of the Original Issue Date shall be equal to $_______",
    Value: "",
  },
  {
    Category: "Common Stock Issuance",
    Subject: "common_stock_issuance_cap_in_financing_or_leasing",
    Location:
      "do not exceed an aggregate of [______] shares of Common Stock (including shares underlying (directly or indirectly) any such Options or Convertible Securities)",
    Value: "",
  },
  {
    Category: "Stock Issuance",
    Subject: "minimum_common_stock_price_threshold",
    Location:
      "at a price of at least $[_____] per share (subject to appropriate adjustment in the event of any stock dividend, stock split, combination or other similar recapitalization with respect to the Common Stock)",
    Value: "",
  },
  {
    Category: "Public Offering",
    Subject: "minimum_proceeds_threshold_from_public_offering",
    Location:
      "offering pursuant to an effective registration statement under the Securities Act of 1933, as amended, resulting in at least $[______] ",
    Value: "",
  },

  {
    Category: "Qualified Direct Listing",
    Subject: "minimum_market_cap_threshold_for_qualified_direct_listing",
    Location: "Corporation is expected to have a market capitalization equal to or greater than $[__________]",
    Value: "",
  },
  {
    Category: "Notice Requirement",
    Subject: "advance_notice_period_for_qualified_financing",
    Location:
      "by written notice sent to the Corporation at least [__] days prior to the consummation of the Qualified Financing",
    Value: "",
  },
  {
    Category: "Redemption Rights",
    Subject: "start_date_for_redemption_installments",
    Location:
      "Corporation at any time on or after [_____________] from the Requisite Holders of written notice requesting redemption of all shares of",
    Value: "",
  },
  {
    Category: "Execution Date",
    Subject: "certificate_execution_date",
    Location:
      "this Amended and Restated Certificate of Incorporation has been executed by a duly authorized officer of this corporation on _________________.",
    Value: "",
  },
];

const replaceUnderLine = (fullText: string, replaceWith: string) => {
  return fullText.replace(/\[_(.*)_*\]/g, replaceWith).replace(/_+/g, replaceWith);
};

const strikeThrough = (fullText: string, replaceWith: string) => {
  // Get the underlined section
  const underlinedSection = fullText.match(/\[_(.*)_*\]/g);
  if (underlinedSection) {
    return fullText.replace(
      underlinedSection[0],
      `<span style="text-decoration: line-through; text-decoration-color: red;">${underlinedSection[0]}</span> <span style="font-weight: bold;">${replaceWith}</span>`,
    );
  }
  return fullText;
};

const replace = async (textToFind: string, replaceWith: string) => {
  if (replaceWith === "NOT FOUND") {
    return;
  }

  const newText = replaceUnderLine(textToFind, replaceWith);
  const comment = `"${textToFind}" --> "${newText}"`;

  await Word.run(async (context) => {
    const searchResults = context.document.body.search(textToFind.substring(0, 254), {
      ignorePunct: true,
      ignoreSpace: false,
    });

    searchResults.load();
    await context.sync();

    // Replace the items to new text.
    for (let i = 0; i < searchResults.items.length; i++) {
      searchResults.items[i].select();
      searchResults.items[i].getComments().getFirstOrNullObject()?.delete();
      searchResults.items[i].insertComment(comment);
      searchResults.items[i].insertText(newText, "Replace");
    }

    await context.sync();
  });
};

// todo: we can do better way on showing the replace text (or replace automatically)
const comment = async (textToFind: string, replaceWith: string) => {
  const newText = replaceUnderLine(textToFind, replaceWith);
  const comment = `"${textToFind}" --> "${newText}"`;

  await Word.run(async (context) => {
    const searchResults = context.document.body.search(textToFind.substring(0, 254), {
      ignorePunct: true,
      ignoreSpace: true,
    });

    searchResults.load();
    await context.sync();

    for (let i = 0; i < searchResults.items.length; i++) {
      searchResults.items[i].getComments().getFirstOrNullObject()?.delete();
      searchResults.items[i].insertComment(comment);
      searchResults.items[i].select();
    }

    await context.sync();
  });
};

const NVCAAssistant = () => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [informationDocument, setInformationDocument] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [filledItems, setFilledItems] = useState<Filler[]>([]);
  const stackTokens: IStackTokens = { childrenGap: 10 }; // Adjust the gap between buttons as needed
  const cardTokens: IStackTokens = { childrenGap: 4 };

  const FilledItemCard: React.FC<Filler> = ({ Category, Location, Value }) => {
    return (
      <Card key={Location} appearance="filled">
        <CardHeader
          image={{
            as: "div",
            children: (
              <>
                {Value === "NOT FOUND" ? (
                  <Tooltip showDelay={0} content={`Unable to find value from term sheet.`} relationship="label">
                    <WarningRegular />
                  </Tooltip>
                ) : (
                  <Tooltip
                    showDelay={0}
                    content={`Replaced with: ${replaceUnderLine(Location, Value)}`}
                    relationship="label"
                  >
                    <InfoRegular />
                  </Tooltip>
                )}
              </>
            ),
          }}
          header={
            <Stack horizontal tokens={cardTokens}>
              <Text weight="semibold">{Category}: </Text>
              <Text>{Value}</Text>
            </Stack>
          }
          description={
            <Stack horizontal={false} tokens={stackTokens}>
              <Caption1>
                <span
                  dangerouslySetInnerHTML={{
                    __html: Value === "NOT FOUND" ? Location : strikeThrough(Location, Value),
                  }}
                />
              </Caption1>
            </Stack>
          }
        //onClick={() => comment(Where, Value)}
        />
        <CardFooter
          className={styles.header}
          action={
            <Stack horizontal tokens={stackTokens}>
              <Button onClick={() => comment(Location, Value)} icon={<Eye12Regular />}>
                Locate
              </Button>
              <Button
                onClick={() => replace(Location, Value)}
                disabled={Value === "NOT FOUND"}
                icon={<Edit12Regular />}
              >
                Replace
              </Button>
            </Stack>
          }
        />
      </Card>
    );
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const arrayBuffer = event.target?.result;
        if (arrayBuffer && typeof arrayBuffer !== "string") {
          // Convert the file content using mammoth
          mammoth
            .extractRawText({ arrayBuffer: arrayBuffer as ArrayBuffer })
            .then((result) => {
              setInformationDocument(result.value); // Set the extracted text
            })
            .catch((error) => {
              console.error("Error reading Word file:", error);
            });
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const fillinDocument = async () => {
    setLoading(true);

    try {
      console.log(informationDocument);

      const results = await conductFillinNVCATemplate(informationDocument, blanks);

      setFilledItems([...results]);
      setLoading(false);
    } catch (error: any) {
      console.log("Error summarizing document", error);
      setError(error.message);
      setLoading(false);
    }
  };

  const commentAll = async () => {
    const promises = filledItems.map((item) => {
      return comment(item.Location, item.Value);
    });

    await Promise.all(promises);
  };

  const replaceAll = async () => {
    const promises = filledItems.map((item) => {
      return replace(item.Location, item.Value);
    });

    await Promise.all(promises);
  };

  return (
    <TabLayout>
      <div className={styles.wrapper}>
        <div>
          <label className={styles.label} htmlFor="file">
            Upload a document such as term sheet that can be used to fill in the template.
          </label>
          <input id="file" type="file" accept=".docx" onChange={handleFileUpload} />
        </div>
        {loading ? (
          <div className={styles.loader}>
            <Spinner />
          </div>
        ) : (
          <>
            {
              <Button
                onClick={() => fillinDocument()}
                icon={<ArrowSyncCircleRegular />}
                disabled={!informationDocument}
              >
                Fill in the NVCA COI template
              </Button>
            }
            {filledItems.length > 0 && (
              <>
                {error && <p>Something went wrong, please try again.</p>}
                <Stack grow tokens={stackTokens}>
                  <Button className={styles.button} onClick={commentAll} icon={<Eye12Regular />}>
                    Locate All
                  </Button>
                  <Button className={styles.button} onClick={replaceAll} icon={<Edit12Regular />}>
                    Replace All
                  </Button>
                </Stack>
              </>
            )}
            {
              filledItems.map((item, index) => (
                <FilledItemCard key={index} {...item} />
              ))
            }
          </>
        )}
      </div >
    </TabLayout >
  );
};

export default NVCAAssistant;
