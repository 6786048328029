import { Link, Text, makeStyles } from "@fluentui/react-components";
import { ApiV2 } from "@vectara/stream-query-client";

const useStyles = makeStyles({
  root: {
    // display: "flex",
    // alignItems: "center",
  },
  flex: {
    // flex: "1 100%",
  },
});

export const ChatReference = ({ result }: { result: ApiV2.Query.SearchResult; position: number }) => {
  const { text } = result;

  const styles = useStyles();
  const url = ""; // TODO: figure out how to get a document's url? maybe go to that location in the document somehow?
  return (
    <>
      <li className={styles.root}>
        <Text size={200}>{url ? <Link href={url}>{text}</Link> : text}</Text>
      </li>
    </>
  );
};
